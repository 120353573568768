import React from 'react'

import './mlStepprocess.css'
import AuctionImage from "../../images/ml-3-step-images/auction.png";
import ProcessingImage from '../../images/ml-3-step-images/processing.png'
import TransportImage from '../../images/ml-3-step-images/transport.png'

export default function MlStepprocess() {
    return (
        <div className="mlstep-container">
        <div className="mlstep-header">
            <h3 className="mlstep-title">
          viaBovemij.nl– Autoveiling in 3 stappen
      </h3>
      <p className="mlstep-description">
        Hoe werkt het? Heel simpel. Heb je je aangemeld voor de veiling?<br/> Dan
        ontvang je per e-mail een uitnodiging zodra er weer een veiling gaat
        starten. <br/> Heb je een uitnodiging ontvangen en wil je meedoen aan de
        veiling? Dan volg je deze stappen:
      </p>

            </div>

            <div className="mlstep-process">
                <div className="process-1">
                    <div className="process-icon">
                    <img className="process-icon-size" src={AuctionImage} alt="AuctionImage" />
                    </div>
                    <div className="steps">
                        <span>
                        Stap 1: &nbsp;
                        </span>
                    </div>
                    <div className="step-heading">
                       <span>
                        Bieden
                       </span>
                    </div>
                    <div className="step-paragraph-1">
                        <p>
                        Je biedt op een bepaalde auto. Het gaat hierbij om een gesloten veiling,
                        wat betekent dat biedingen van andere deelnemers niet zichtbaar zijn. De
                       veiling staat 48 uur open. In die tijd kun je meerdere biedingen doen,
                       waarbij jouw laatste bod geldt.

                        </p>
                    </div>

                </div>

                <div className="process-2">
                <div className="process-icon">
                    <img className="process-icon-size"src={ProcessingImage} alt="ProcessingImage" />
                    </div>
                    <div className="steps">
                        <span>
                        Stap 2: &nbsp;
                        </span>
                    </div>
                    <div className="step-heading">
                       <span>
                        Verwerken

                        </span>
                    </div>
                    <div className="step-paragraph-2">
                        <p>
                        Nadat de veiling gesloten is, ontvangt onze veilingpartner alle biedingen, inclusief het hoogste bod. Uiterlijk 48 uur na sluiting van de veiling, hoor je of je de veilig hebt gewonnen
                        </p>
                    </div>

                </div>

                <div className="process-3">
                <div className="process-icon">
                    <img  className="process-icon-size" src={TransportImage} alt="TransportImage" />
                    </div>
                    <div className="steps">
                        <span>
                        Stap 3: &nbsp;
                        </span>
                    </div>
                    <div className="step-heading">
                        
                        Bezorgen

                       
                    </div>
                    <div className="step-paragraph-3">
                        <p>
                        Heb je de veiling gewonnen? Gefeliciteerd, je kunt nu heel eenvoudig de kooptransactie afronden. Vervolgens ontvang je de auto binnen 5 werkdagen.
                        </p>
                    </div>

                </div>



            </div>


            
        </div>

    )
}
