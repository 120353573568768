import React from "react";
import LPHeaderComponent from "./lpHeaderComponent";
import "../../containers/landingPage.css";
import LPFooterfirstComponent from "./lpFooterfirstComponent"
function PrivacyPage(props) {
  return (
    <div className="landingpage-wrapper">
      <div className="landingpage-container">
        <LPHeaderComponent />
        <div className="privacy-landingpage-detail-wrapper">
          <h1 className="privacy-landingpage-detail-title">
            Privacyverklaring
          </h1>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl B.V. (VB) hecht veel waarde aan de bescherming van de
            privacy van onze websitebezoekers. In de rest van onze Privacy-en
            Cookieverklaring gebruiken we viaBovemij.nl, waarmee het
            viaBovemij.nl platform bedoelen en duiden we VB vaak aan als
            ViaBovemij.{" "}
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Over ViaBovemij.nl </strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            viaBovemij.nl is het mobiliteitsplatform dat inspeelt op
            marktontwikkelingen met nieuwe digitale mogelijkheden om efficiëntie
            te verhogen en kosten van mobiliteitsbedrijven te verlagen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Jouw privacy is voor ons van groot belang. Wij houden ons dan ook
            aan de geldende privacywetgeving. Dit betekent dat je gegevens
            veilig bij ons en onze partners zijn en dat wij en onze partners ze
            altijd zorgvuldig gebruiken. Wij zullen je persoonlijke gegevens
            nooit verkopen aan derde partijen. In deze Privacyverklaring
            informeren wij je over de wijze waarop wij omgaan met je
            persoonsgegevens.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Mobi-ID account vereist</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl is uitsluitend toegankelijk voor aangesloten bedrijven
            in de mobiliteitsbranche die beschikken over een Mobi-ID. Voor het
            aanvragen van een Mobi-ID zie https://www.mobi-id.nl. Op de
            verwerkingen van persoonsgegevens in verband met het Mobi-ID zijn de
            voorwaarden van Mobi-ID van toepassing. Wij verwijzen je daarvoor
            naar de website van Mobi-ID.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Zakelijke gegevens en persoonsgegevens</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Zoals hiervoor aangegeven is viaBovemij.nl alleen toegankelijk voor
            bedrijven die beschikken over een Mobi-ID. Het Mobi-ID staat op naam
            van het bedrijf. De gegevens die door viaBovemij over het bezoek en
            gebruik van het viaBovemij.nl platform worden verwerkt zijn dan ook
            voornamelijk zakelijke gegevens.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Daarnaast kunnen er bij Mobi-ID functionarissen aan een bedrijf
            gekoppeld worden; de gegevens die van die functionarissen worden
            verwerkt zijn veelal beperkt tot zakelijke contactgegevens (zie voor
            details de website van Mobi-ID). Dat maakt dan ook dat er ook
            persoonsgegevens kunnen worden verwerkt, daarover geven we in dit
            statement uitleg.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Verder is het zo dat waar de onderneming en de natuurlijk persoon
            samenvallen (zoals bij eenmanszaken) of elkaar dicht(er) raken
            (zoals bij VOFs of BV’s met de persoonsnaam er in), veel of wellicht
            wel alle verwerkte gegevens ook als persoonsgegevens moeten worden
            gezien.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Die persoonsgegevens worden dan echter wel steeds in een zakelijke
            context verwerkt. Dat wil zeggen dat VB gegevens die verband houden
            met het gebruik van het platform viaBovemij.nl zal koppelen aan het
            (aan het betreffende Mobi-ID gekoppelde) betreffende bedrijf, niet
            aan de natuurlijk persoon die (namens dat bedrijf) van het Mobi-ID
            gebruik maakt.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Alles wat in dit document staat over het verwerken van
            (persoons)gegevens moet dan ook steeds in die zakelijke context
            worden gezien.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Verwerking van persoonsgegevens</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl verwerkt voor verschillende doeleinden
            persoonsgegevens. Daarbij zijn soms partners betrokken. De
            verschillende verwerkingen staan hieronder weergegeven en uitgelegd.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>1) Toegang en gedrag op portaal</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Wij houden bij wat je (althans het aan jou gekoppelde Mobi-ID) op
            viaBovemij.nl gedaan hebt en wanneer. Dit doen we om je bedrijf
            beter van dienst te zijn. Zo kunnen wij suggesties aanbieden van
            andere diensten die voor je bedrijf mogelijk interessant zijn. Ook
            proberen wij zo een beeld te krijgen van marktontwikkelingen hopen
            we met de gegevens onze dienstverlening te kunnen verbeteren.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            We verwerken daarbij de gegevens uit het Mobi-ID account en gegevens
            over de diensten die je bedrijf via viaBovemij.nl hebt afgenomen en
            de daarbij verwerkte gegevens. Bij diensten die verband houden met
            voertuigen betreft dit bijvoorbeeld het kenteken en de
            kilometerstand. Bij andere diensten kan dit andere gegevens
            betreffen. Zie daarover ook verderop bij “ViaBovemij en andere
            aanbieders”.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Deze gegevens hebben wij nodig, of maken het ons makkelijker, om
            diensten aan te kunnen bieden. Wij bewaren deze informatie zodat je
            ze niet steeds opnieuw in hoeft te vullen en we je makkelijker
            kunnen benaderen als dat nodig is bij de dienst. Ook kunnen wij de
            gegevens voor eigen doeleinden gebruiken (zie verderop).
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>2) Contactpagina en nieuwsbrief</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Via onze contactpagina kun je ons vragen stellen of aanvragen doen.
            Je kunt hier terecht voor al je vragen, opmerkingen en klachten. Het
            maakt daarbij niet uit of die vragen gaan over het aanbod van
            ViaBovemij zelf of één van de aanbieders op viaBovemij.nl (zie over
            die andere aanbieders verderop in het document).
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Hiervoor gebruiken wij je e-mailadres, telefoonnummer, NAW-gegevens,
            geslacht en overige (persoons)gegevens in je bericht. De verwerking
            van de persoonlijke informatie is noodzakelijk om je vraag of
            opmerking te kunnen verwerken. Wij bewaren deze informatie totdat we
            zeker weten dat je tevreden bent met onze reactie en aansluitend
            drie maanden. Zo kunnen we bij vervolgvragen de informatie er
            makkelijk bij pakken. Ook kunnen we zo onze klantenservice trainen
            om nog beter te worden.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            In veel gevallen kunnen we ook vragen over diensten van andere
            aanbieders direct (namens die andere aanbieder) beantwoorden.
            Wanneer je een vraag stelt over een reeds gesloten contract, kunnen
            wij namelijk gegevens daarover terugvinden in je klantprofiel (zie
            uitleg verderop). Ook eerdere contactmomenten kunnen wij daar
            terugvinden. Dit maakt het veelal mogelijk je snel en efficiënt te
            helpen. Wanneer wij de vraag niet zelf kunnen beantwoorden, zullen
            wij je vraag doorsturen naar de betreffende aanbieder.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Je kunt je abonneren op onze nieuwsbrief. Hierin lees je nieuwtjes,
            tips en informatie over onze producten en diensten. Dit abonnement
            kun je op ieder moment opzeggen. Iedere nieuwsbrief bevat een
            afmeldlink. Via het portaal kun je dit ook doorgeven. Je e-mailadres
            wordt alleen met je toestemming toegevoegd aan de lijst van
            abonnees. Deze gegevens worden bewaard tot een maand nadat je het
            abonnement hebt opgezegd.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>3) Contact aanbieder voor verwijzingen</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl is een platform waarop diensten van derde partijen
            aangeboden worden. Verderop in het statement leggen we dit verder
            uit.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Bij een deel van de diensten van derden is onze rol beperkt tot het
            verwijzen naar die aanbieder. In die gevallen kun je via
            viaBovemij.nl namens je bedrijf eenvoudig contact opnemen met de
            aanbieder. ViaBovemij.nl verwerkt hiervoor persoonsgegevens en zorgt
            dat de gegevens bij de aanbieder terecht komen. Zie het kopje
            ‘Verstrekken van je persoonsgegevens aan derden’ en ‘ViaBovemij en
            andere aanbieders’ voor meer informatie omtrent de werkwijze van
            viaBovemij.nl. viaBovemij.nl verstrekt je persoonsgegevens
            (zakelijke contactgegevens) in het kader van de door jou (namens je
            bedrijf) gevraagde dienst.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong> 4) Verwerkingen bij geïntegreerd aanbod</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            viaBovemij.nl is bij uitstrek hét platform voor oplossingen voor
            aangesloten bedrijven in de mobiliteitsbranche. We helpen je bedrijf
            dan ook graag bij het vinden van de mobiliteitsoplossing die past
            bij de behoefte. Teneinde je een zo compleet mogelijk aanbod te
            kunnen doen via het platform werken we samen met partners, die we
            een plek op de website aanbieden.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Bij steeds meer aanbieders verwijzen wij je niet naar de aanbieder
            (zie hiervoor punt 3), maar is het aanbod van de aanbieder
            geïntegreerd in ons platform. Dat betekent dat je bedrijf via ons
            platform een contract met die aanbieder kan afsluiten (wij zijn bij
            dat contract dan dus geen partij). Daarbij kunnen dan
            persoonsgegevens worden verwerkt van de persoon of personen die het
            bedrijf vertegenwoordigen. De verwerking van persoonsgegevens is
            anders bij dit geïntegreerde aanbod dan bij de verwijzingen als
            hiervoor onder 3 beschreven. Zie daarvoor de uitleg verderop in het
            statement.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>5) Cookies</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Wanneer je onze website bezoekt en/of gebruik maakt van onze
            website, verzamelen wij automatisch de gegevens die naar ons worden
            gestuurd door je computer, mobiele apparatuur of andere apparatuur
            waarmee je toegang hebt. Deze gegevens kunnen worden verzameld met
            behulp van cookies. Voor meer informatie over de cookies, kun je
            onze Cookie pagina bekijken.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>ViaBovemij en andere aanbieders</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl is van VB. Onze contactgegevens vind je onderaan dit
            statement.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Op viaBovemij.nl worden diensten van diverse partijen aangeboden. We
            hebben dit hiervoor al kort uitgelegd, in deze paragraaf gaan we
            hier dieper op in.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Er zijn in de kern twee manieren waarop diensten van derden op
            ViaBovemij.nl worden aangeboden:
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            1. Verwijzing: de aanbieder presenteert zich op het platform, maar
            voor de transactie word je verwezen naar de omgeving van de
            aanbieder om daar vervolgens namens je bedrijf de transactie te
            sluiten.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            2. Geïntegreerd: je sluit de transactie namens je bedrijf via het
            platform af.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Ad. 1. Privacy en verwijzing</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Bij de eerste categorie kun je jezelf namens je bedrijf op ons
            platform oriënteren en informatie verzamelen. Voor de transactie
            word je verwezen naar de (fysieke of digitale) omgeving van de
            aanbieder.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Een voorbeeld van de eerste categorie is bijvoorbeeld een aanbieder
            waarmee via het platform contact wordt gelegd, maar die vervolgens
            fysiek langs komt of telefonisch contact zoekt om de transactie te
            sluiten of af te ronden.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wij geven je bedrijfsgegevens en je contactgegevens nooit ‘zomaar’
            door aan deze aanbieders. Wij doen dat alleen op het moment dat je
            hierom vraagt, bijvoorbeeld door het invullen van een
            contactformulier en een vraag om een dienst of informatie
            betreffende een propositie waarbij de aanbieder betrokken is.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De aanbieders verwerken je gegevens en die van je bedrijf verder
            conform hun eigen privacystatement. Bekijk dat statement goed
            voordat je ons verzoekt je gegevens door te geven. Wij hebben geen
            invloed op de verwerking door de aanbieder na de doorgifte.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            viaBovemij.nl gebruikt bij verwijzingen je e-mailadres ook om een
            enquête te sturen in het kader van kwaliteitsverbeteringen: ervaring
            op viaBovemij.nl en bij de aanbieder te meten en het optimaliseren
            van de website-ervaring. De grondslag hiervoor is het
            gerechtvaardigd belang van viaBovemij.nl. Wanneer we
            persoonsgegevens gaan gebruiken voor commerciële doeleinden, en je
            niet eerder een dienst van ons hebt afgenomen, vragen we eerst om je
            toestemming.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Verder vindt op viaBovemij.nl monitoring plaats. Monitoring vindt
            plaats ten behoeve van het bewaken van de integriteit van onze
            dienstverlening en het detecteren en voorkomen van misbruik op onze
            website en van onze diensten. Monitoring bestaat onder andere uit
            het registreren, beoordelen en filteren van e-mailadressen en/of
            telefoonnummers die veelvuldig en/of voor oneigenlijke doeleinden
            worden gebruikt bij het invullen van formulieren, zoals het
            aanvragen van een proefrit. De gegevens worden voor geen andere
            doeleinden gebruikt dan het bewaken van de integriteit van onze
            dienstverlening en worden niet langer dan noodzakelijk bewaard.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Ad. 2. Privacy bij geïntegreerd aanbod</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Bij de tweede categorie aanbieders – het geïntegreerde aanbod –
            werkt het gemakkelijker. Je hoeft hierbij ons platform niet te
            verlaten en kunt, namens je bedrijf, alles in één omgeving doen: van
            informatie opvragen tot het afsluiten van contracten met de
            verschillende aanbieders.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Het aantal aanbieders in deze tweede categorie zal gaandeweg steeds
            meer groeien. Een actueel overzicht van de verschillende aanbieders,
            en hun contactgegevens, <a href="https://viabovemij.nl/ ">vind je hier. </a> Dit overzicht kan van tijd tot tijd
            wijzigen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <u>Verwerking door aanbieders geïntegreerde aanbod</u>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wij hebben afspraken gemaakt met de aanbieders van het geïntegreerde
            aanbod over je privacy. Je hoeft dus niet meer vele verschillende
            privacystatements te lezen. Na het lezen van dit statement weet je
            wat er met je persoonsgegevens gebeurt.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Met de aanbieders van het geïntegreerde aanbod is afgesproken dat
            zij je persoonsgegevens – in de praktijk zal dit veelal beperkt zijn
            tot je zakelijke contactgegevens – ook overeenkomstig dit
            privacystatement verwerken.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Deze aanbieders zullen je persoonsgegevens dan ook louter verwerken
            voor de volgende doeleinden:
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            1. het uitvoeren van de met jouw onderneming gesloten
            overeenkomst(en);
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            2. het voeren van de administratie, alsmede andere activiteiten van
            intern beheer;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            3. het berekenen, vastleggen en innen van verschuldigde bedragen,
            waaronder begrepen het in handen van derden stellen van vorderingen;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            4. om met je in contact te kunnen treden en te reageren op door jou
            gestelde vragen of verzoeken om informatie;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            5. het informeren over nieuwe producten en diensten;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            6. voor het verbeteren van de dienstverlening;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            7. voor het voldoen aan wettelijke verplichtingen, zoals de
            administratie- en bewaarplicht;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            8. voor de uitwisseling in het kader van de dienstverlening via het
            platform als hierna beschreven.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Voor andere verwerkingen zullen de aanbieders toestemming aan je
            vragen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <u>Uitwisseling met aanbieders</u>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wanneer je een dienst van een aanbieder gebruikt, worden daarmee ook
            gegevens gedeeld tussen die aanbieder en ViaBovemij. Het delen van
            gegevens gebeurt om verschillende redenen, namelijk:
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            1. omdat het technisch noodzakelijk is of de dienst vergemakkelijkt;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            2. om je gegevens te verrijken zodat we je beter van dienst zijn;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            3. voor statistische doeleinden;
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            4. om te voldoen aan wettelijke verplichtingen.{" "}
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <em>Ad 1. Technisch noodzakelijk of vergemakkelijken dienst</em>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De meeste aanbieders gebruiken hun eigen IT-systemen voor het
            aanbieden van hun diensten op viaBovemij.nl. Als gebruiker merk je
            daar niets van; je blijft gewoon op viaBovemij.nl, maar de systemen
            zijn ‘onder water’ aan elkaar gekoppeld.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Dit betekent dus ook dat onze systemen automatisch de gegevens aan
            de IT-systemen van de aanbieder doorgeven die noodzakelijk zijn om
            de dienst te kunnen leveren (zoals een ID-nummer), of die het
            leveren van de dienst voor je bedrijf vergemakkelijken (zoals het
            alvast invullen van je bedrijfsgegevens of contactgegevens).
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Omgekeerd geldt ook dat wij betrokken kunnen zijn bij de uitvoering
            van (een deel van) de dienst van de betreffende aanbieder. Daarvoor
            worden er dus weer gegevens van het IT-systeem van de aanbieder aan
            ons systeem doorgegeven. Zo kan het bijvoorbeeld zijn dat de
            betaling verloopt via een door ons geselecteerde bank of payment
            service provider. Wij krijgen dan bijvoorbeeld doorgegeven of de
            betaling gelukt is of niet.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De meeste uitwisselingen van gegevens in deze categorie zijn
            tijdelijk. Dat wil zeggen dat de gegevens weer worden verwijderd
            zodra de transactie is afgerond. Een deel van de gegevens wordt
            echter bewaard voor je profiel of voor statistiek (zie hierna bij 3)
            of omdat we daartoe wettelijk verplicht zijn (zie hierna bij 4).
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <em>Ad. 2. Gegevens verrijken</em>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wanneer je bedrijf een dienst afneemt bij een aanbieder, dan geven
            zij ook weer gegevens over de transactie aan ons terug. Welke
            gegevens het betreft verschilt per dienst, maar het gaat steeds om
            meest kenmerkende gegevens van de transactie. <a href="https://viabovemij.nl/">Op de pagina</a>
            {" "} kun je een uitwerking vinden
            van de gegevens die per dienst worden bewaard.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Dit betreft overigens veelal geen persoonsgegevens, nu deze gegevens
            aan het bedrijf en niet aan de persoon worden gekoppeld. Zie
            hierover de uitleg hiervoor onder “Zakelijke gegevens en
            persoonsgegevens”.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Voor zover de gegevens wel persoonsgegevens zijn, kun je bij ons een
            inzageverzoek doen wanneer je wilt weten welke persoonsgegevens we
            precies van je verwerken (zie onderaan het statement). Op termijn
            kun je waarschijnlijk een deel van die gegevens al zien na inloggen
            op het portaal.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Verder wordt op het platform bijgehouden welk contact je bedrijf of
            jijzelf namens je bedrijf eerder hebt gehad met de klantenservice
            (zie ook verderop in het statement).
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            We gebruiken deze gegevens om je bedrijf beter van dienst te zijn.
            Zo kunnen we je op basis van je profielinformatie een aanbod doen
            van onszelf, dezelfde of een andere aanbieder op ons platform. De
            grondslag hiervoor is het gerechtvaardigd belang. Uiteraard kun je
            jezelf altijd afmelden voor dergelijke dienstverlening (zie ook over
            je rechten verderop in dit statement).
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De onderliggende logica van de profilering komt in de kern op het
            volgende neer. Wij houden bij welke producten en diensten je bedrijf
            afneemt via viaBovemij.nl en proberen aan de hand daarvan suggesties
            te doen over andere producten en diensten die wellicht bij je
            passen. Dat doen we door te kijken naar individuele kenmerken van je
            transactie (bijv. de einddatum van een contract, om dan tegen die
            datum een aanbod te kunnen doen), maar ook naar in hoeverre je
            transactie lijken op andere klanten. De gevolgen voor je bedrijf van
            deze analyses zijn beperkt: het bedrijf ontvangt hooguit een extra
            aanbieding of viaBovemij.nl brengt bepaalde informatie wellicht wat
            prominenter in beeld. De profilering zal echter niet leiden tot het
            uitsluiten van bepaald aanbod, noch het rekenen van hogere prijzen
            of andere soortgelijke nadelige gevolgen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <em>Ad. 3. Statistische doeleinden</em>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Verder maken wij statistische analyses van de gegevens die we
            verkrijgen op grond van de hiervoor onder 1 en 2 beschreven
            gegevensstromen. Dit doen we voor onze eigen bedrijfsvoering en ook
            om deze gegevens te kunnen gebruiken voor onder meer de ontwikkeling
            van nieuwe producten en diensten. De grondslag voor het anonimiseren
            (en de verdere analyse) is gelegen in het gerechtvaardigd belang.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De statistische analyses zijn volledig geanonimiseerd. Het gaat ons
            er niet om wat je op ons platform doet, maar wat de gemiddelde
            gebruiker hier wel en niet doet. De conclusies die aan de analyse
            worden verbonden zijn ook op geen enkele wijze meer tot je te
            herleiden.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            De geanonimiseerde statistische analyses kunnen met derde partijen
            worden gedeeld.{" "}
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <em>Ad. 4. Naleving wettelijke verplichtingen</em>
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Op grond van de wet zijn we verplicht gegevens over de via ons
            platform verrichte transactie te bewaren. Dit vloeit bijvoorbeeld
            voort uit belastingwetgeving of wetgeving op het gebied van
            administratieplichten, mede gelet op de afspraken die met de
            aanbieders hebben gemaakt over het gebruik van het platform (waarbij
            er soms betaald wordt per transactie). Daar waar de wet ons
            verplicht om de van de aanbieder ontvangen gegevens te bewaren
            zullen we dat dus ook doen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            <u>Gezamenlijke verantwoordelijkheid bij geïntegreerd aanbod</u>{" "}
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            {" "}
            Zoals hiervoor beschreven ontvangen wij bij het geïntegreerde aanbod
            gegevens over het gebruik van de diensten van de aanbieders op het
            platform. Voor die uitwisseling van persoonsgegevens, en het daaruit
            voortvloeiende gebruik (profielopbouw en statistische analyse), zijn
            wij, ViaBovemij, samen met de aanbieder(s) gezamenlijk
            verantwoordelijk in de zin van artikel 26 AVG.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Voor alle vragen over die profielopbouw en die statistische analyse
            kun je bij ons terecht. Je kunt hiervoor contact met ons zoeken (zie
            onderaan). Op termijn kun je ook inloggen op het portaal om direct
            (een deel van) de gegevens te zien en te controleren.{" "}
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Volledigheidshalve moeten we er op wijzen dat je zowel de aanbieder
            als ons kunt aanspreken met vragen over die uitwisselingen en het
            daaruit voortvloeiende gebruik (profielopbouw en statistische
            analyse). De rol van de aanbieder is daarbij echter beperkt. De
            profielopbouw en de statistische analyse wordt door ons gedaan, niet
            door de aanbieder. De rol van de aanbieder is beperkt tot het
            aanleveren van de gegevens aan ons (en zijn eigen rol in het
            verlenen van de door hem aangeboden diensten). De aanbieder is dan
            ook alleen op de doorgifte aan ons aan te spreken, niet op de
            verdere verwerkingen door ons.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Voor de helderheid: de gezamenlijke verantwoordelijkheid ziet alleen
            op de hier beschreven doeleinden van profielopbouw en statistiek. De
            aanbieder blijft altijd zelf verantwoordelijk voor de verwerkingen
            van persoonsgegevens in verband met de via het platform gesloten
            transacties. Zie daarover de uitleg hiervoor onder het kopje “
            Verwerking door aanbieders geïntegreerde aanbod”.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Verstrekken van je persoonsgegevens aan derden</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Wij kunnen je persoonsgegevens verstrekken aan derden in
            overeenstemming met deze privacyverklaring en de toepasselijke wet-
            en regelgeving. Dit kan op de volgende wijzen gebeuren:
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            {" "}
            Voor het aanbod van producten en diensten van viaBovemij.nl door
            onze partners, zoals hiervoor uitgelegd onder het kopje “ViaBovemij
            en andere aanbieders”. Voor de helderheid: dit zullen veelal
            zakelijke gegevens zijn, zoals hiervoor uitgelegd onder “Zakelijke
            gegevens en persoonsgegevens”.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wij maken daarnaast gebruik van de diensten van derden om je
            persoonsgegevens te verwerken. Deze derden treden op als verwerker
            voor viaBovemij.nl. Dat betekent dat zij de gegevens nooit voor
            eigen doeleinden mogen gebruiken. De persoonsgegevens worden door
            onze verwerkers verwerkt op beveiligde servers binnen de EU of
            binnen landen welke volgens de AVG voldoende betrouwbare privacy
            wetgeving hebben. viaBovemij.nl heeft in overeenstemming met de
            Europese en Nederlandse privacyregelgeving verwerkersovereenkomsten
            gesloten met al haar verwerkers.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Wij zullen je persoonsgegevens niet bekend maken aan
            rechtshandhavers, andere overheidsfunctionarissen of andere derden
            zonder gerechtelijk bevel of formeel verzoek van de overheid in
            overeenstemming met geldende wetgeving, tenzij viaBovemij.nl te
            goeder trouw meent dat de bekendmaking van informatie
            gerechtvaardigd is. viaBovemij.nl verstrekt je persoonsgegevens aan
            derden voor zover dat op grond van rechtspraak, wet- en regelgeving
            vereist is (zoals houders van intellectuele eigendomsrechten,
            toezichthouders, Belastingdienst, politie en andere wettelijke
            instanties). Wij zullen je in beginsel vooraf informeren over een
            dergelijke verstrekking, tenzij dit wettelijk gezien niet is
            toegestaan. Ook kan viaBovemij je persoonsgegevens verstrekken
            indien dit noodzakelijk is voor het verlenen van de door jou
            verzochte dienst.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Bewaartermijnen</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Voor (eventuele) overige gegevens geldt dat wij je gegevens niet
            langer bewaren dan noodzakelijk voor de verwerkelijking van de
            doeleinden waarvoor zij zijn verzameld en verwerkt. Wij verwijderen
            je gegevens eveneens indien geconstateerd wordt dat deze zijn
            verouderd of wanneer je om verwijdering verzoekt (en wij geen
            geldige reden hebben voor langer bewaren). Gegevens die gekoppeld
            zijn aan een account, worden bewaard tot het account wordt
            verwijderd.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            Vorengaande bewaartermijnen gelden niet indien er sprake is van een
            wettelijke uitzondering, of ingeval we deze gegevens langer nodig
            hebben voor onze dienstverlening of een geschil. Je kunt ook een
            verzoek indienen om je persoonsgegevens te verwijderen, deze
            mogelijkheid vind je terug onder ‘rechten van betrokkenen’.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Beveiliging</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Wij zullen alle redelijke organisatorische en technische
            beveiligingsmaatregelen treffen om je persoonsgegevens te beveiligen
            en deze te beschermen tegen verlies en oneigenlijk gebruik of
            aanpassing.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Inzage en wijzigen van je gegevens</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou
            hebben, kun je altijd contact met ons opnemen. Zie de
            contactgegevens hieronder. Je hebt de volgende rechten:
          </p>
          {/* <ul className="privacy-landingpage-detail-list"> */}
            <li className="privacy-landingpage-detail-list">
              a) uitleg krijgen over welke persoonsgegevens we hebben en wat we
              daarmee doen;
            </li >
            <li className="privacy-landingpage-detail-list">b) inzage in de precieze persoonsgegevens die we hebben;</li>
            <li className="privacy-landingpage-detail-list">c) het laten corrigeren van fouten;</li>
            <li className="privacy-landingpage-detail-list">d) het laten verwijderen van verouderde persoonsgegevens;</li>
            <li className="privacy-landingpage-detail-list">e) intrekken van toestemming;</li>
            <li className="privacy-landingpage-detail-list">f) bezwaar maken tegen een bepaald gebruik</li>
            <li className="privacy-landingpage-detail-list">g)het recht op gegevensoverdraagbaarheid</li>
          {/* </ul> */}

          <p className="privacy-landingpage-detail-subtitle">
            Let op dat je altijd duidelijk aangeeft wie je bent, zodat we zeker
            weten dat we geen gegevens van de verkeerde persoon aanpassen of
            verwijderen.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Klacht indienen</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Als je een klacht hebt of een andere vraag omtrent de verwerking van
            je persoonsgegevens, kun je een e-mailbericht sturen naar
            privacy@bovemij.nl. Wij vragen je hierbij zoveel mogelijk informatie
            omtrent je klacht of vraag mee te zenden. Wij zullen klachten en
            vragen in behandeling nemen en uiterlijk binnen vier weken reageren.
            Wij doen er alles aan om je zo goed mogelijk van dienst te zijn. Je
            hebt ook het recht om een klacht in te dienen bij de Autoriteit
            Persoonsgegevens, de contactgegevens van de Autoriteit
            Persoonsgegevens kan je{" "}
            <a href="https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/beveiliging/beveiliging-van-persoonsgegevens">
              hier
            </a>{" "}
            vinden.
          </p>
          <h4 className="landingpage-detail-subtitle-subheader">
            <strong>Contactgegevens</strong>
          </h4>
          <p className="privacy-landingpage-detail-subtitle">
            Voor vragen omtrent privacy kun je contact opnemen met Functionaris
            Gegevensbescherming van Bovemij N.V. waar ViaBovemij.nl B.V.
            onderdeel van uit maakt. De contactgegevens van de Functionaris
            gegevensbescherming zijn hieronder opgenomen.
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            ViaBovemij.nl B.V.{" "}
          </p>
          <p className="privacy-landingpage-detail-subtitle">Takenhofplein 2</p>
          <p className="privacy-landingpage-detail-subtitle">
            6538 SZ Nijmegen
          </p>
          <p className="privacy-landingpage-detail-subtitle">
            privacy@bovemij.nl
          </p>
        </div>
        <LPFooterfirstComponent/>
      </div>
    </div>

  );
}

export default PrivacyPage;
