import React from "react";
import dateFormat from "dateformat";
import _ from 'lodash';
import "./RegistratieInformatieContainer.css";

function RegistratieInformatieContainer({registrations}) {
 
  return (
    <React.Fragment>

      <div className="registratieinformatie-container-title">
        <h4>Registratie informatie</h4>
      </div>
      {registrations ? registrations.map((e) =>{return(
  <div className="registratieinformatie-container-body-block">
        <div className="registratieinformatie-container-body-columns">
        <div className="registratieinformatie-container-body-columns-info-block">
            <span className="registratieinformatie-container-body-columns-info-block-title">
            Datum
            </span>
            <span className="registratieinformatie-container-body-columns-info-block-value">
            {!_.isEmpty(e?.eventDate)
                ? dateFormat(e?.eventDate,"dd-mm-yyyy")
                : "-"}
            </span>
          </div>
        
          <div className="registratieinformatie-container-body-columns-info-block">
            <span className="registratieinformatie-container-body-columns-info-block-title">
            Soort registratie
            </span>
            <span className="registratieinformatie-container-body-columns-info-block-value">
            
            {!_.isEmpty(e?.eventType)
                ? e?.eventType
                : "-"}
            </span>
          </div>
          
          <div className="financien-container-body-columns-info-last-block">
            <span className="registratieinformatie-container-body-columns-info-block-title">
            Omschrijving
            </span>
            <span className="registratieinformatie-container-body-columns-info-block-value">
            {!_.isEmpty(e?.eventRemark)
                ? e?.eventRemark
                : "-"}
            </span>
          </div>
        </div>
        
       
        </div>
)} ): (
  <p>Onbekend</p>
)}
      
    </React.Fragment>
  );
}

export default RegistratieInformatieContainer;
