import React from "react";
import _ from 'lodash';
import "./FinancienContainer.css";

function FinancienContainer(props) {
  let vatDeductable = props?.vatDeductable === true ? "Ja" : props?.vatDeductable === false ? "Nee" :( props?.vatDeductable === 'UnKnown'||props?.vatDeductable === 'Unknown') ? "Onbekend": '-';
  let BrutoBPM = props?.bpm;
  let RestBPM = props?.restbpm;

  const formatter = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });
  return (
    <React.Fragment>
      <div className="financien-container-title">
        <h4>Financieel</h4>
      </div>

      {!_.isEmpty(props.nieuwprijs) ? (
        "Onbekend"
      ) : (
        <div className="financien-container-body-block">
          <div className="financien-container-body-columns">
            
              <div className="financien-container-body-columns-info-block">
                <span className="financien-container-body-columns-info-block-title">
                  BTW verrekenbaar
                </span>
                <span className="financien-container-body-columns-info-block-value">
                  {vatDeductable}
                </span>
              </div>
           
            <div className="financien-container-body-columns-info-last-block">
              <span className="financien-container-body-columns-info-block-title">
                Nieuwprijs (in BTW BPM)
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {_.isEmpty(props.nieuwprijs)
                  ? formatter.format(props.nieuwprijs)
                  : "-"}
              </span>
            </div>
            {/* <div className="financien-container-body-columns-info-last-block">
              <span className="financien-container-body-columns-info-block-title">
              Fiscale waarde
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {NieuwprijsRDW === "" && noData}
              </span>
            </div> */}
          </div>
          <div className="financien-container-body-columns">
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Bruto BPM
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {_.isEmpty(BrutoBPM)
                  ? formatter.format(BrutoBPM)
                  : "-"}
              </span>
            </div>

            <div className="financien-container-body-columns-info-last-block">
              <span className="financien-container-body-columns-info-block-title">
                Rest BPM
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {_.isEmpty(RestBPM)
                  ? formatter.format(RestBPM)
                  : "-"}
              </span>
            </div>

            {/* <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Optiebedrag
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {Optiebedrag === "" && noData}
              </span>
            </div> */}
            {/* <div className="financien-container-body-columns-info-last-block">
              <span className="financien-container-body-columns-info-block-title">
              Als taxi gebruikt
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {Taxi === "" && noData}
              </span>
            </div> */}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default FinancienContainer;
