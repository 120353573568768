import React,{useState} from 'react'
import './slider.style.css'
import Slider from "react-slick";

import MlSilder1 from "../../images/ml-silder_1.png";
import MlSilder2 from "../../images/ml-silder_2.png";
import MlSilder3 from "../../images/ml-silder_3.png";
import MlSilder4 from "../../images/ml-silder_4.png";
import MlSilder5 from "../../images/ml-silder_5.png";
import MlSilder6 from "../../images/ml-silder_6.png";
import SlideNextIcon from "../Icons/ML/slideNextIcon";
import SlidePrevIcon from "../Icons/ML/slidePrevIcon";
import CrossCloseButtonIcon from "../Icons/crossCloseButtonIcon";
import ArrowPrevCarpassportCarousal from "../Icons/arrowPrevCarpassportCarousal";
import ArrowNextCarpassportCarousal from "../Icons/arrowNextCarpassportCarousal";


import { Modal } from "@mui/material";




function SliderComponent(props){
    const [open, setOpen] = useState(false);
  const [initialModalSlider,setInitialModalSlider] = useState(0);

  const handleOpen = (index) => {
    setInitialModalSlider(index);
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };
//   const executeOnClick = (isExpanded) => {
//     console.log(isExpanded);
//   };
  const settings = {
   
    infinite: true,
   
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    nextArrow: (
      <div>
        <SlideNextIcon />
      </div>
    ),
    prevArrow: (
      <div>
        <SlidePrevIcon />
      </div>
    ),
    // responsive: [
    //   {
    //     breakpoint: 375,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 1366,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //     },
    //   },
    // ],
  };
  const onNextClick = () => {
    // medias?.map((media, index) => setIndex(index + 1));
  };
  const onPrevClick = () => {
    // medias?.map((media, index) => setIndex(index - 1));
  };
  let prevArrow = {
    position: "absolute",
    top: "111%",
    zIndex: "100",
  };

  let nextArrow = {
    position: "absolute",
    top: "111%",
    zIndex: "100",
  };

  let prevArrowModal = {
    height: "80px",
    zIndex: "1000",
    width: "80px",
    position: "relative",
    top: "151%",
    left: "56px",
    backgroundImage: "linear-gradient(to right,#90a0b7, transparent)",
    textAlign: "center",
    padding: "16px",
    cursor: "pointer"
  };

  let nextArrowModal = {
    height: "80px",
    zIndex: "1000",
    width: "80px",
    position: "relative",
    top: "150%",
    left:"-56px",
    cursor: "pointer",
    right: "0px",
    backgroundImage: "linear-gradient(to left,#90a0b7, transparent)",
    textAlign: "center",
    padding: "16px",


  };

  const modalsettings = {
   
   
    infinite:true,
    speed:500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide : initialModalSlider,
    // arrows: true,
    // fade: true,
    prevArrow: (
      <ArrowPrevCarpassportCarousal
        onClick={onPrevClick}
        modal={props?.isModalVisible}
        styleProp={props?.isModalVisible === true ? prevArrow : prevArrowModal}
      
      />
    ),
    nextArrow: (
      <ArrowNextCarpassportCarousal
        onClick={onNextClick}
        modal={props?.isModalVisible}
        styleProp={props?.isModalVisible === true ? nextArrow : nextArrowModal}
      />
    ),
    // responsive: [
    //   {
    //     breakpoint: 375,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,

    //     },
    //   },
    //   {
    //     breakpoint: 1366,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //     },
    //   },
    // ],
  };
//   const settingsThumbs = {
//     slidesToShow: 5,
//     slidesPerRow: 1,
//     slidesToScroll: 1,
//     asNavFor: ".slider-for",
//     centerMode: true,
//     dots: true,
//     draggable: true,
//     swipeToSlide: true,
//     focusOnSelect: true,
//     centerPadding: "10px",
//     swipeToSlide: true,
//     arrows: false,
//     responsive: [
//       {
//         breakpoint: 375,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 1366,
//         settings: {
//           slidesToShow: 3,
//           slidesToScroll: 3,
//         },
//       },
//     ],
//   };
  const mlslider = [
    {
      src: MlSilder1,
      index: 1,
    },
    {
      src: MlSilder2,
      index: 2,
    },
    {
      src: MlSilder3,
      index: 3,
    },
    {
      src: MlSilder4,
      index: 4,
    },
    {
      src: MlSilder5,
      index: 5,
    },
    {
      src: MlSilder6,
      index: 6,
    },
  ];
    
return(
    <div className="mlDescription-wrap-slider">
   

        <div className="slider-container">

        
      <Slider {...settings}>
        {mlslider.map((sliderimage, index) => {
          return (
            <>
                <img
                
                  src={sliderimage.src}
                  alt="MlSilder"
                  id={index}
                  onClick={()=>handleOpen(index)}
                  style={{ width:"100%",cursor: 'pointer'}}
                />
            
            </>
          );
        })}
      </Slider>
      </div>
      <div className="modal-container">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        // width={"60%"}
      >
        <div className="marketing-landing-desc-image-modal">
          <div
            onClick={handleClose}
            className="marketing-landing-desc--close-modal-icon-container"
          >
            <CrossCloseButtonIcon />
          </div>
          <Slider   
          {...modalsettings}
           className="ml-modal-slider_1">
            {mlslider.map((sliderimage, index) => {
              return (
                <>
                  <div className = "modal-slider-big-image-component">
                    <img
                      src={sliderimage.src}
                      alt="MlSilder"
                      id={index}
                      style={{width:"100%" ,height:"100%"}}
                    />
                  </div>
                </>
              );
            })}
          </Slider>

        </div>
      </Modal>
    </div>
  </div>
)

        }

export default SliderComponent


