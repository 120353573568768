import React from "react";

function CrossCloseButtonIcon() {
  return (
    <div>
      <svg
        width="30"
        height="30"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M19 6.40999L17.59 5L12 10.59L6.41 5L5 6.40999L10.59 12L5 17.5899L6.41 18.9999L12 13.4099L17.59 18.9999L19 17.5899L13.41 12L19 6.40999Z"
          fill="#ffffff"
        />
      </svg>
    </div>
  );
}

export default CrossCloseButtonIcon;
