import React, { useEffect, useState } from "react";
import PlaceABid from "./BidForm/place-a-bid";
import InfoIcon from "../../Icons/infoIcon";
import StopWatchIcon from "../../Icons/stopwatchIcon";
import RunningIcon from "../../Icons/headerIcon/runningIcon";
import BackButtonCarpassportHeaderIcon from '../../Icons/backButtonCarpassportHeaderIcon';
import "./topHeader.css";
import dateFormat from "dateformat";
import moment from "moment";
import { Popover } from "antd";
import "antd/dist/antd.css";
import { useHistory } from "react-router-dom";
import _ from "lodash";

function TopHeader({
  accesstoken,
  userid,
  catalogID,
  lotID,
  vehicleId,
  bidStatus,
  openForBidding,
  startBiddingDate,
  startBiddinghours,
  startBiddingminute,
  enddayofbidding,
  modelType,
  brand,
  selectedVehicleIdProp,
  transmission,
  minBid,
  isNavigationNeeded,
  vehiclesList
}) {
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();
  let [visible, setVisible] = useState(false);
  let [flag, setFlag] = useState(false);
  
  let now = moment(new Date());
  let end = moment(enddayofbidding);

  let remainingdays = moment.duration(end.diff(now)).days();
  let totalhoursleft = remainingdays * 24;

  let diff = moment.duration(end.diff(now));

  let remainingtimehours = diff.asHours();
  let hours = remainingtimehours.toFixed(0);
  const history = useHistory();

  const handleVisibleChange = () => {
    setVisible(!visible);
  };
  let interval;
  const startTimer = () => {
    let countDownDate = new Date(enddayofbidding).getTime();
    interval = setInterval(() => {
      let now = moment(new Date().getTime());
      const distance = countDownDate - now;
      if (distance < 0) {
        // Stop Timer

        clearInterval(interval.current);
      } else {
        var remainingtimesecondss = Math.floor(distance / 1000);
        var remainingtimeminutes = Math.floor(remainingtimesecondss / 60);
        var remainingtimehours = Math.floor(remainingtimeminutes / 60);
      
        remainingtimeminutes %= 60;
        remainingtimesecondss %= 60;

    
        setTimerHours(remainingtimehours);
        setTimerMinutes(remainingtimeminutes);
        setTimerSeconds(remainingtimesecondss);
      }
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  });
  

  useEffect(() => {
    if (totalhoursleft && totalhoursleft >= 2) {
      setFlag(!flag);
    }
  }, []);

  if (hours >= 2 && hours > 0) {
    flag = !flag;
  }

  let hourss = timerHours !== undefined ? timerHours.toString() : "" 
  const hoursSplit = hourss.split("");
  if (hoursSplit[1] == null) {
    hoursSplit[1] = hoursSplit[0];
    hoursSplit[0] = 0;
  }

  let minutess =timerMinutes !== undefined ? timerMinutes.toString() : ""
  let minutesSplit = minutess.split("");
  if (minutesSplit[1] == null) {
    minutesSplit[1] = minutesSplit[0];
    minutesSplit[0] = 0;
  }

  let minutesTensProps = minutesSplit && minutesSplit[0];
  let minutesUnitsProps = minutesSplit && minutesSplit[1];
  let secondss = timerSeconds !== undefined ? timerSeconds.toString() : ""

  const secondsSplit = secondss.split("");
  if (secondsSplit[1] == null) {
    secondsSplit[1] = secondsSplit[0];
    secondsSplit[0] = 0;
  }

  const backButtonOnclick = () => {
    const selectedUniqueVehicle = _.uniqBy(
      vehiclesList,
      "catalogID"
    );
    const catalogIDs = selectedUniqueVehicle.map((vehicle)=>vehicle.catalogID);
    
    history.push({
      pathname:  "/AvailableCars",
      state: {
        catalogIDs: catalogIDs,
        vehiclesList:vehiclesList
      } 
   });
  }
  
  return (
    <React.Fragment>
      {isNavigationNeeded?
        <button
          className="carpassport-back-btn-header"
          onClick={()=>backButtonOnclick()}
        >
          <BackButtonCarpassportHeaderIcon />
          <span className="carpassport-back-btn-title">Terug</span>
        </button>:null}
      <RunningIcon />
      <div class="col-second-place span_1_of_3-place">
        {/* <div className="carpassport-form-label">
          <span className="field-label">PLAATS JOUW BOD</span>
        </div>*/}
        <div className="carpassport-input-form">
          <div className="carpassport-form-input">
            <PlaceABid
              accesstoken={accesstoken}
              userid={userid}
              openForBidding={openForBidding}
              catalogID={catalogID}
              lotID={lotID}
              vehicleId={vehicleId}
              bidStatus={bidStatus}
              enddayofbidding={enddayofbidding}
              modelType={modelType}
              brand={brand}
              transmission={selectedVehicleIdProp.transmission}
              selectedVehicleIdProp={selectedVehicleIdProp}
              minBid={minBid}
              vehiclesDetail={vehiclesList}
            />
          </div>
          <div className="carpassport-form-description">
            <span className="carpassport-form-description-title">
              Koperskosten vanaf € 280,- + transportkosten vanaf € 99,-
            </span>
          </div>
        </div> 
      </div>
      <div class="col-second-middle span_1_of_3-place">
        <div className="carpassport-timer-label">
          <StopWatchIcon width="55px" height="51px" color="#fff" />
        </div>
        <div className="carpassport-timer-start">
          <span className="carpassport-start-date-title">
            Startdatum en tijd
          </span>
          <div className="carpassport-start-date">
            {dateFormat(openForBidding,'dd-mm-yy')}  {dateFormat(openForBidding,'HH')}:{startBiddingminute}
          </div>
        </div>
      </div>
      <div class="col-second-end span_1_of_3-place">
        <div className="carpassport-timer-label">
          <div className="carpassport-body-hours-container">
            {hours >= 2 && hours >= 0 && hoursSplit[0] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "carpassport-body-hours-card-48"
                    : "carpassport-body-hours-card-2"
                }
              >
                <span className="carpassport-body-block">
                  {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
                </span>
              </div>
            ) : (
              <div className="hours-card">
                <div
                  className={
                    hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                      ? "carpassport-body-hours-card-2"
                      : "carpassport-body-hours-card-48"
                  }
                >
                  <span className="carpassport-body-block">
                    {hoursSplit[0] >= 0 ? hoursSplit[0] : null}
                  </span>
                </div>
              </div>
            )}
            {hours >= 2 && hours >= 0 && hoursSplit[1] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[0] >= 0
                    ? "carpassport-body-hours-card-48"
                    : "carpassport-body-hours-card-2"
                }
              >
                <span className="carpassport-body-block">
                  {hoursSplit[1] >= 0 ? hoursSplit[1] : null}
                </span>
              </div>
            ) : (
              <div className="hours-card">
                <div
                  className={
                    hours < 2 && hours >= 0 && hoursSplit[0] >= 0
                      ? "carpassport-body-hours-card-2"
                      : "carpassport-body-hours-card-48"
                  }
                >
                  <span className="carpassport-body-block">
                    {hoursSplit[1] >= 0 ? hoursSplit[1] : "0"}
                  </span>
                </div>
              </div>
            )}

            {hours >= 2 && hours >= 0 && hoursSplit[2] >= 0 ? (
              <div
                className={
                  hours >= 2 && hours >= 0 && hoursSplit[2] >= 0
                    ? "carpassport-body-hours-card-48"
                    : "carpassport-body-hours-card-2"
                }
              >
                <span className="carpassport-body-block">
                  {hoursSplit[2] >= 0 ? hoursSplit[2] : null}
                </span>
              </div>
            ) : null}
          </div>
          <span className="carpassport-body-hours-title">Uren</span>
        </div>

        <span className="carpassport-body-timer-colon">:</span>
        <div className="carpassport-timer-label">
          <div className="carpassport-body-hours-container">
            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48"
                  : "carpassport-body-hours-card-2"
              }
            >
              <span className="carpassport-body-block">
                {minutesTensProps >= 0 ? minutesTensProps : "0"}
              </span>
            </div>

            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48"
                  : "carpassport-body-hours-card-2"
              }
            >
              <span className="carpassport-body-block">
                {minutesUnitsProps > 0 ? minutesUnitsProps : "0"}
              </span>
            </div>
          </div>
          <span className="carpassport-body-hours-title">Minuten</span>
        </div>
        <span className="carpassport-body-timer-colon">:</span>
        <div className="carpassport-timer-label">
          <div className="carpassport-body-hours-container">
            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48"
                  : "carpassport-body-hours-card-2"
              }
            >
              <span className="carpassport-body-block">
                {secondsSplit[0] > 0 ? secondsSplit[0] : "0"}
              </span>
            </div>

            <div
              className={
                flag
                  ? "carpassport-body-hours-card-48"
                  : "carpassport-body-hours-card-2"
              }
            >
              <span className="carpassport-body-block">
                {secondsSplit[0] > 0 ? secondsSplit[1] : "0"}
              </span>
            </div>
          </div>

          <span className="carpassport-body-hours-title">Seconden</span>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TopHeader;
