import React from "react";

import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./mlfaq.css";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
export default function MlFAQComponent() {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <div className="faq-container">
        <div className="faq-header">
          <h1 className="faq-title">
            Meestgestelde vragen over de autoveiling
          </h1>
          <p className="faq-subtitle">
            {" "}
            Hieronder vind je de antwoorden op de meestgestelde vragen over de
            autoveiling. Staat jouw vraag er niet tussen? Neem dan contact op
            met je accountmanager van Bovemij. Wij helpen je graag verder!
          </p>
        </div>
        <div className="faq-content">
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Waarom zijn Bovemij en viaBOVAG.nl met Online Autoverkoopservice
                gestart?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                De markt van tweedehands voertuigenaanbod is explosief gegroeid,
                waardoor er een tekort is ontstaan in de aantallen en
                diversiteit in de voorraad van voertuigen van viaBOVAG.nl-leden.
                Daarnaast hebben viaBOVAG.nl-leden geen toegang tot de markt in
                de onderlinge verkopen van voertuigen door consumenten (C2C).
                Doordat viaBOVAG.nl ook een propositie heeft ontwikkeld (Online
                Autoverkoopservice) waarbij consumenten hun voertuig kunnen
                aanbieden aan een kopende partij (Biddo), komen deze voertuigen
                exclusief beschikbaar voor viaBOVAG.nl-leden.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Waarom de keuze voor Biddo?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                ViaBOVAG.nl wil zich onderscheiden in de markt door voor de
                consument een betrouwbaar en transparant innameproces te
                organiseren. Biddo is daarin gespecialiseerd. Biddo organiseert
                het innameproces bij de consument thuis. Dit zorgt voor veel
                verkoopgemak voor de consument. Om de gekochte auto’s weer terug
                te brengen in het BOVAG-netwerk, is het logisch dat het
                verkooptraject ook vanuit Biddo wordt doorgezet. Biddo is bekend
                met de logistiek van het kopen, het verkopen, het transporteren
                van het voertuig. Dit maakt de cirkel rond.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Welke marge behaalt Biddo op de inkoop?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                De precieze inkoopprijs door Biddo is vertrouwelijk en zullen we
                niet bekendmaken. Het is wel in een ieders belang dat
                marktwerking haar rol speelt – de consument aan de ene kant als
                verkopende partij zal altijd akkoord moeten gaan met het bod op
                zijn auto en aan de andere kant moet de auto door de
                veilingdeelnemers als een aantrekkelijke object beschouwd
                worden.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wordt er op ieder voertuig een bod uitgebracht door Biddo?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Nee, er wordt een bod uitgebracht op de voertuigen die passen
                binnen de voorwaarden die gesteld zijn. Zie voor deze
                voorwaarden onderstaande uitleg over welke voertuigen op de
                veiling worden aangeboden. Dit zijn ook de voertuigen waar een
                bod op uitgebracht wordt.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wat zijn de leeftijden van de auto’s die worden aangeboden op de
                veiling?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div>
                  <ul>
                    <li>
                      Auto’s jonger dan 10 jaar, ongeacht de kilometerstand en
                      ongeacht geldigheid APK
                    </li>
                    <li>
                      Auto’s van 10 t.m. 15 jaar oud met en/of een
                      kilometerstand van minder dan 200.000 km. en/of minimaal 2
                      maanden geldige APK
                    </li>
                    <li>Auto’s ouder dan 15 jaar worden niet aangeboden </li>
                  </ul>
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Aan welke kenmerken voldoet het aanbod op de veiling verder?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div>
                  <ul>
                    <li>
                      Een logische kilometerstand. De RDW beoordeelt of de
                      geregistreerde stand een logisch verloop laat zien. Biddo
                      neemt het oordeel van de RDW over
                    </li>
                    <li>Een geldig Nederlands kenteken (geel of grijs)</li>
                    <li>
                      Personen- en lichte bestelwagens met een gewicht van
                      minder dan 3.500 kilogram. Personenwagens wegen vrijwel
                      altijd minder dan 3.500 kilogram. Voor bedrijfswagens
                      geldt dat lichte bedrijfswagens of light commercial
                      vehicles (LCV)wel worden aangeboden
                    </li>
                    <li>
                      Auto’s met lichte visuele en/of rijdbare schade. De Biddo
                      taxateurs beoordelen of de eventuele schade aan het
                      voertuig “lichte schade” is
                    </li>
                    <li>
                      Niet te vaak overschreven op naam. Bijvoorbeeld: een jonge
                      auto die in de eerste 4 maanden van zijn bestaan 4 keer
                      overschreven is op naam, dat is ongebruikelijk. Voor een
                      oude auto die in de laatste 3 maanden 3 keer is
                      overschreven geldt hetzelfde
                    </li>
                    <li>
                      Zonder WOK-status. WOK staat voor “Wacht Op Keuren”. Dit
                      is een melding die op het kenteken geplaatst wordt door de
                      politie. Het houdt in dat het voertuig niet aan de
                      wettelijke eisen voldoet en dat deze herkeurd moet worden
                      voordat deze weer de openbare weg op mag
                    </li>
                  </ul>
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Worden er ook geïmporteerde voertuigen aangeboden?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <p>
                  Ja, geïmporteerde voertuigen worden o.a. onder de volgende
                  voorwaarden aangeboden:
                </p>
                <div>
                  <ul>
                    <li>
                      Het voertuig is geïmporteerd uit een land binnen Europa.
                    </li>
                    <li>
                      De onderhoudshistorie van het voertuig is volledig bekend.
                    </li>
                    <li>De BPM komt overeen met de normale bruto BPM.</li>
                    <li>Er is sprake van reguliere kentekenafgifte.</li>
                  </ul>
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Hoe ziet de veiling eruit?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                De veiling wordt aangeboden op viaBovemij.nl. Voordat deze
                voertuigen worden aangeboden organiseert Biddo een innameproces
                waarbij de auto wordt gekeurd, wordt er een proefrit gemaakt en
                foto’s gemaakt t.b.v. plaatsing in de veiling.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Vanaf welke prijs bieden?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Elk te veilen voertuig heeft een vanafprijs. Hiermee zijn
                deelnemers van te voren geïnformeerd en kunnen zij zelf bepalen
                wel/niet een bod op het betreffende voertuig uit te brengen.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Wanneer gewonnen?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Na de veiling ontvangt Biddo alle biedingen, inclusief het
                hoogste bod. De gunning hiervan is ter beoordeling van Biddo, op
                basis van het hoogste bod.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Hoeveel tijd zit er tussen het ontvangen van de biedingen en de
                gunning/oordeel van Biddo?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Biddo zal de gunning binnen 48 uur bekend maken.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wordt RDC voertuigdata (VTS XML) gebruikt?{" "}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Er wordt gebruik gemaakt de RDC data. Hiermee worden
                dataconflicten voorkomen.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Vanaf welke prijs kan ik bieden?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Ieder voertuig wordt aangeboden met een vanaf prijs.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Hoe werkt deze Autoveiling?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Iedere deelnemer kan per auto een bod plaatsen, verhogen,
                verlagen of annuleren zonder dat de andere bieders dit zien.
                Oftewel er is geen sprake van een veiling op basis van opbod.
                Het bod van andere bieders is niet te zien.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Welke informatie krijg ik te zien?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Alle relevante voertuiginformatie, inclusief foto’s,
                tekortkomingen (schade/defecten) als deze visueel waarneembaar
                zijn. En de veilingkosten die bij aankoop van een voertuig in
                rekening worden gebracht.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wanneer weet ik of ik de veiling heb gewonnen?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Na de veiling ontvangt Biddo alle biedingen, inclusief het
                hoogste bod. De gunning hiervan is ter beoordeling van Biddo, op
                basis van het hoogste bod en wordt binnen 48 uur bekend gemaakt.
                Het winnende bod wordt niet gedeeld met de overige
                veilingdeelnemers.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wat gebeurt er als ik de veiling heb gewonnen?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                De winnaar van de veiling ontvangt hiervan een orderbevestiging
                en is verplicht te betalen. Wanneer de order is betaald, wordt
                de auto gevrijwaard en tenaamgesteld en kan de auto in de
                voorraad worden opgenomen. Na deze stappen gaat de auto op
                transport en wordt deze binnen 5 werkdagen afgeleverd.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wat gebeurt er met het voertuig als er geen bod wordt
                uitgebracht?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Het voertuig wordt nogmaals aangeboden op de eerstvolgende
                autoveiling. Wordt het voertuig niet verkocht, dan mag het
                voertuig binnen het netwerk van Biddo worden verkocht.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Hoe vaak vindt een veiling plaats?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Het aantal veilingen is afhankelijk van het aanbod ingekochte
                auto’s op viaBOVAG.nl. Wij streven naar minimaal 2 a 4 veilingen
                per maand.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Hoeveel autobedrijven kunnen per veiling deelnemen?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Wij vinden het belangrijk dat alle deelnemers aan de veiling een
                goede kans hebben daadwerkelijk een auto te kunnen kopen. Daarom
                streven wij naar een optimale verhouding van het autobedrijf tot
                het aantal transacties, oftewel beschikbare auto’s in de
                veiling. Hoe deze er precies uitziet, zal de ervaring leren.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Wie kan aan de veiling deelnemen?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                De veiling is uitsluitend toegankelijk voor BOVAG-leden.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Wat gebeurt er bij een gelijke bieding?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Het tijdstip van het bod is leidend. Degene die als eerste het
                hoogste bod heeft uitgebracht wint de veiling.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Wat zijn de veilingkosten?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                <div>
                  <p>De veilingkosten bestaan uit 2 elementen:</p>
                  <p>
                    1. Veilingkosten zijn afhankelijk van de verkoopprijs van
                    het voertuig.
                    <br /> Hiernaast vind je de veilingkosten per
                    prijscategorie:
                  </p>
                </div>
                <div>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1}>
                      <Grid container item spacing={3}>
                        <React.Fragment>
                          <Grid item xs={4}>
                            <div>
                              <div>
                                <b>Verkoopprijs</b>
                              </div>
                              <div>tot € 5.000</div>
                              <div>€ 5.001 - € 10.000</div>
                              <div>€ 10.001 - € 15.000</div>
                              <div>€ 15.001 - € 20.000</div>
                              <div>{">"} € 20.001</div>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div>
                              <div>
                                <b>Veilingkosten</b>
                              </div>
                              <div>€280,-</div>
                              <div>€330,-</div>
                              <div>€405,-</div>
                              <div>€455,-</div>
                              <div>€530,-</div>
                            </div>
                          </Grid>
                          <Grid item xs={4}></Grid>
                        </React.Fragment>
                      </Grid>
                    </Grid>
                  </Box>
                  <span>Deze veilingkosten zijn ten gunste van Biddo.</span>
                  <p>
                    Hiervoor faciliteren zij het veilingplatform, maken zij
                    foto’s van het voertuig, keuren ze het voertuig en verzorgen
                    ze het proefrijden, de documentatie en de vrijwaring.
                  </p>
                  <p>
                    2. Transportkosten <br /> Deze kosten zijn ten gunste van
                    Biddo. Biddo organiseert het transport binnen 5 werkdagen.
                    Hieronder zie je de transportkosten per type voertuig:
                  </p>
                  <ul>
                    <li>€99,- personenauto’s</li>
                    <li>€109,- kleine bedrijfswagen</li>
                    <li>€159,- grote bedrijfswagen</li>
                    <li>Overige maten o.b.v. calculatie</li>
                  </ul>
                  <p>Genoemde bedragen zijn excl. BTW.</p>
                </div>
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                De auto voldoet niet aan mijn verwachtingen. En nu?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Mocht het gebeuren dat er onvoorziene zaken naar voren komen,
                dan kan er binnen 48 uur bij Biddo worden gereclameerd. Biddo
                neemt vervolgens contact op om eventuele opties te bespreken.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Wat gebeurt er bij een klacht over het geleverde voertuig?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Biddo zal bij reclamatie een onafhankelijk expertise laten
                uitvoeren. De uitkomst daarvan wordt gebruikt om te kiezen voor:
                auto retour, vergoeding van de geconstateerde waardevermindering
                of reparatie door Biddo.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Hoe worden consumenten geworven voor de Online
                Autoverkoopservice van viaBOVAG.nl?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Consumenten worden via online kanalen en Tv-commercials
                geworven. De teksten op viaBOVAG.nl zijn aangepast in “verkoop
                uw auto tegen een eerlijke prijs”.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>
                Op welke manier wordt Biddo getoond in de Tv-commercial?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Het logo van Biddo wordt niet (meer) in beeld gebracht bij de
                TV-commercial; wel wordt in de voice over over Biddo gesproken.
                Dit is de minimale vereiste van de Reclame Code Commissie.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              id="panel2-header"
            >
              <Typography>Hoe kan ik me aanmelden voor de veiling?</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography>
                Wil jij een uitnodiging ontvangen voor de volgende veiling en
                toegang krijgen tot een exclusief aanbod van consumentenauto’s?
                Meld je dan nu hier alvast aan via de "Voorinschrijving /
                Aanmelden autoveiling".
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    </>
  );
}
