import React from 'react';

import { useHistory } from "react-router";
import './landingPageComponent.css';
import logo from '../../images/logo.png';
import LogoIcon from '../Icons/ML/logoIcon';
import { Button } from 'antd';

function LPHeaderComponent(props) {
  let hostURL = process.env.REACT_APP_SERVER_URL;
  const history = useHistory()
  const loginClickHandler =() =>{
    window.location.href =hostURL + `/api/login`
    history.replace('',window.location.href);
  }
  const loginHomePageClickHandler =() =>{
    history.replace('',hostURL + `/`)
  }
    return (
        <div className="lpHeader-container">
            <div className="lpHeader-logo" onClick={loginHomePageClickHandler}>
                {/* <LogoIcon />\ */}
                <img src={logo} alt="logo"></img>
            </div>
            <div className="lpHeader-container-landingpage-detail-button">
            <Button type="button" className="btn--primary--solid" onClick={loginClickHandler}>
            Inloggen
            </Button>
          </div>
        </div>
    );
}

export default LPHeaderComponent;