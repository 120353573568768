import React from "react";
import "./mlDescription.style.css";
import ShowMoreText from "react-show-more-text";
import SliderComponent from "./slidercomponent";
export default function MlDescriptionpage() {

 
  const executeOnClick = (isExpanded) => {
    // console.log(isExpanded);
  };
  return (
    <div className="container-description">
      <div className="description">
        <h1 className="heading-primary">Tijd voor actie en innovatie</h1>
        <div className="full-description">
        <ShowMoreText
            /* Default options */
            lines={3}
            more="zie meer"
            less="zie minder"
            className="showLessText"
            anchorClass="my-anchor-css-class"
            onClick={executeOnClick}
            expanded={true}
            keepNewLines={false}
            truncatedEndingComponent={"... "}
          >
          <p className="description-top">
            De vraag naar tweedehands voertuigen is explosief gegroeid, waardoor
            er een tekort is ontstaan in aantallen en diversiteit bij onze BOVAG
            leden.
          </p>

          <ul className="descrition-list">
            <li>
              <p>
                Sinds afgelopen zomer kunnen consumenten transparant en snel hun
                auto verkopen viaBovag.nl. “We spelen daarmee in op een
                klantbehoefte; uit consumentenonderzoek is gebleken dat andere
                inkooppartijen niet uitblinken in transparantie en daadkracht.
                Dat wilden wij anders doen. Nu kunnen we het aanbod terug laten
                vloeien naar de autobedrijven die gebruik maken van onze
                platformen”, aldus Wouter van Kesteren, directeur viaBovag.nl.
                Directeur Eva Pepping van viaBovemij.nl licht toe: “Het is een
                gesloten veiling, exclusief voor onze viaBovag.nl. en Bovemij
                leden, zodat er geen sprake is prijsopdrijving. Onze leden
                hoeven niet bang te zijn dat dit concurreert met hun eigen
                inkoopkanalen omdat veel van deze auto’s anders ook niet bij het
                autobedrijf terecht zouden komen. Op korte termijn zullen er
                meerdere veilingen per week plaatsvinden.
              </p>
            </li>
            <li>
              <p>
                Dit zal gebeuren via het platform viaBovemij.nl. Het nieuwe
                innovatieve digitale platform van Bovemij waar autobedrijven in
                de toekomst alles op het gebied van inkoop, verkoop, aftersales
                en risicobescherming kunnen regelen. We willen ze helpen nog
                succesvoller te ondernemen, in een steeds meer digitale
                omgeving.” Wil jij daar bij zijn? Meld je dan nu alvast aan.{" "}
              </p>
            </li>
          </ul>
          <p>Voordelen voor jou als autobedrijf</p>

          <ul className="description-sublist">
            <li>Exclusief voor BOVAG-leden.</li>
            <li>
              Gesloten veiling. Dit betekent dat er slechts 1 bieding per auto
              per deelnemer kan worden uitgebracht.
            </li>
            <li>
              Je kunt bieden op auto’s die door consumenten zijn verkocht op
              viaBovag.nl.
            </li>
            <li>
              Deelname aan de veiling is gratis; heb je de veiling gewonnen dan
              betaal je veiling- en transportkosten
            </li>
            <li>
              Meer zekerheid doordat de auto’s al zijn ingekocht door onze
              veilingpartner Biddo.
            </li>
            <li>
            </li>
          </ul>
          </ShowMoreText>
        </div>
      </div>
      <div className="slider">
        <h1 className="slider-heading">Voorbeeld:</h1>

        <div>
          <SliderComponent />
        </div>
      </div>
    </div>
  );
}
