import { Button } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import LPFooterfirstComponent from "../components/LandingPage/lpFooterfirstComponent";
import LPHeaderComponent from "../components/LandingPage/lpHeaderComponent";
import LPHeroImageComponent from "../components/LandingPage/lpHeroImageComponent";
import "./landingPage.css";

function LandingPage(props) {
  let hostURL = process.env.REACT_APP_SERVER_URL;
  const history = useHistory();
  const loginClickHandler = () => {
    window.location.href = hostURL + `/api/login`;
    history.replace("", window.location.href);
  };
  return (
    <div className="landingpage-wrapper" data-testid = "landing-page-container">
      <div className="landingpage-container">
        <LPHeaderComponent />
        <LPHeroImageComponent />
        <div className="description-landingpage-detail-wrapper">
          {/* <div className="description-landingpage-detail-title">
            Welcome to the Pilot version of the new Digital Mobility Platform
          </div> */}
          <div className="description-landingpage-detail-subtitle">
            <span>
              Op deze pagina log je in voor viaBovemij.nl – Autoveiling. De
              eerste auto’s zijn binnen en we starten de veiling met een klein
              aantal autobedrijven. Aan de hand van deze ervaringen zullen we de
              dienstverlening optimaliseren en de veiling opschalen.
            </span>
            <br/>
            <span>
              Behoor jij tot de eerste groep geselecteerde autobedrijven? Log
              dan in om deel te nemen aan de viaBovemij.nl – Autoveiling.
            </span>
          </div>
          <div className="description-landingpage-detail-button">
            {/* <Button
              type="button"
              className="btn--primary--solid"
              onClick={loginClickHandler}
            >
              Inloggen
            </Button> */}
          </div>
        </div>
        <LPFooterfirstComponent />
      </div>
    </div>
  );
}

export default LandingPage;
