import React from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import {
  // BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import Navbar from "../components/navbar";
import MiniDrawer from "../components/Navbar/MiniDrawer";
import PrivateRoute from "./privateroute";
import UserProfile from "../containers/user-profile";
import UserOrganization from "../containers/user-organization";
import MarketingLandingpage from "../containers/marketing-landingpage";
import LandingPage from "../containers/landingPage";
import PrivacyPage from "../components/LandingPage/privacyPage";
import PageNoFoundComponent from "../components/PageNotFound/PageNotFound";
export const LocationDisplay = () => {
  const location = useLocation()

  return <div data-testid="location-display">{location.pathname}</div>
}
function Routes() {
  // const [isUrlAuthentication, setIsUrlAuthentication] = useState(true);
  const location = useLocation();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const useLocations = queryString.parse(location.search);
  // eslint-disable-next-line no-unused-vars
  const serverURL = process.env.REACT_APP_HOST_URL;
  // eslint-disable-next-line no-unused-vars
  const hostURL = process.env.REACT_APP_SERVER_URL;
  // eslint-disable-next-line no-unused-vars
  const getToken = localStorage.getItem("Token");
  // eslint-disable-next-line no-unused-vars
  const getUserid = localStorage.getItem("userid");
  return (
    <Switch data-testid="route-content">
      <Route exact path="/autoveiling" component={MarketingLandingpage}/>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/Privacyverklaring" component={PrivacyPage} />
      <PrivateRoute path="/userprofile" component={UserProfile} />
      <PrivateRoute path="/userOrganization" component={UserOrganization} />
      <PrivateRoute path="/landingpage" component={MiniDrawer} />
      {localStorage.getItem("Token") ? <MiniDrawer /> : ""}
      <Route component={PageNoFoundComponent} />
    </Switch>
  );
}

export default Routes;
