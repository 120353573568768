import React from "react";
import _ from "lodash";
import "./OptiesContainer.css";

function OptiesContainer(props) {
  let optionsProp = props?.nonGenericOptionsProp;
  let noData = "-";
  
  let airco =
    String(props.airco) === "true"
      ? "Ja"
      : String(props.airco) === "false"
      ? "Nee"
      : "-";
  let ecc =
    String(props.ecc) === "true"
      ? "Ja"
      : String(props.ecc) === "false"
      ? "Nee"
      : "-";
  let audioInstallation =
    String(props.audioInstallation) === "true"
      ? "Ja"
      : String(props.audioInstallation) === "false"
      ? "Nee"
      : "-";
  let centralLock =
    String(props.centralLock) === "true"
      ? "Ja"
      : String(props.centralLock) === "false"
      ? "Nee"
      : "-";
  let cruiseControl =
    String(props.cruiseControl) === "true"
      ? "Ja"
      : String(props.cruiseControl) === "false"
      ? "Nee"
      : "-";
  let heatedSeats =
    String(props.heatedSeats) === "true"
      ? "Ja"
      : String(props.heatedSeats) === "false"
      ? "Nee"
      : "-";
  let automatedSeats =
    String(props.automatedSeats) === "true"
      ? "Ja"
      : String(props.heatedSeats) === "false"
      ? "Nee"
      : "-";
  let electricWindows =
    String(props.electricWindows) === "true"
      ? "Ja"
      : String(props.electricWindows) === "false"
      ? "Nee"
      : "-";
  let navigationBig =
    String(props.navigationBig) === "true"
      ? "Ja"
      : String(props.navigationBig) === "false"
      ? "Nee"
      : "-";
  let navigationSmall =
    String(props.navigationSmall) === "true"
      ? "Ja"
      : String(props.navigationSmall) === "false"
      ? "Nee"
      : "-";
  let startStop =
    String(props.startStop) === "true"
      ? "Ja"
      : String(props.startStop) === "false"
      ? "Nee"
      : "-";
  let powerSteering =
    String(props.powerSteering) === "true"
      ? "Ja"
      : String(props.powerSteering) === "false"
      ? "Nee"
      : "-";
  let roofRail =
    String(props.roofRail) === "true"
      ? "Ja"
      : String(props.roofRail) === "false"
      ? "Nee"
      : "-";
  let ledXenon =
    String(props.ledXenon) === "true"
      ? "Ja"
      : String(props.ledXenon) === "false"
      ? "Nee"
      : "-";
  let alloywheels =
    String(props.alloywheels) === "true"
      ? "Ja"
      : String(props.alloywheels) === "false"
      ? "Nee"
      : "-";
  let airsuspension =
    String(props.airsuspension) === "true"
      ? "Ja"
      : String(props.airsuspension) === "false"
      ? "Nee"
      : "-";
  let foglights =
    String(props.foglights) === "true"
      ? "Ja"
      : String(props.foglights) === "false"
      ? "Nee"
      : "-";
  let pdc =
    String(props.pdc) === "true"
      ? "Ja"
      : String(props.pdc) === "false"
      ? "Nee"
      : "-";
  let sunroof =
    String(props.sunroof) === "true"
      ? "Ja"
      : String(props.sunroof) === "false"
      ? "Nee"
      : "-";
  let xenon =
    String(props.xenon) === "true"
      ? "Ja"
      : String(props.xenon) === "false"
      ? "Nee"
      : "-";
  let led =
    String(props.led) === "true"
      ? "Ja"
      : String(props.led) === "false"
      ? "Nee"
      : "-";
  let groupName = optionsProp?.name;

  return (
    <React.Fragment>
      <div className="opties-container-title">
        <h4>Opties</h4>
      </div>

      {_.isEmpty(props) ? (
        "Onbekend"
      ) : (
        <div className="opties-container-body-block">
          <div className="opties-container-body-columns">
            {airco === 'Ja' && !_.isEmpty(airco)  ? (<div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Airconditioning
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(airco) ? airco : <p>{noData}</p>}
              </span>
            </div>)
            : null}
           {ecc === 'Ja' && !_.isEmpty(ecc)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                ECC
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(ecc) ? ecc : noData}
              </span>
            </div>
           ): null}
            {audioInstallation === 'Ja' && !_.isEmpty(audioInstallation)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Audio installatie
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(audioInstallation)
                  ? audioInstallation
                  : noData}
              </span>
            </div>
            ): null}
            
            {centralLock === 'Ja' && !_.isEmpty(centralLock)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Centrale deurvergrendeling
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(centralLock) ? centralLock : noData}
              </span>
            </div> ): null}
            {cruiseControl === 'Ja' && !_.isEmpty(cruiseControl)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Cruise control
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(cruiseControl)
                  ? cruiseControl
                  : noData}
              </span>
            </div>) : null}
            {heatedSeats === 'Ja' && !_.isEmpty(heatedSeats) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Stoelverwarming
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(heatedSeats) ? heatedSeats : noData}
              </span>
            </div>) : null}
            {automatedSeats === 'Ja' && !_.isEmpty(automatedSeats) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Electrisch bedienbare stoelen
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(automatedSeats)
                  ? automatedSeats
                  : noData}
              </span>
            </div>) : null}
            {electricWindows === 'Ja' && !_.isEmpty(electricWindows) ? (  <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Electrische raambediening
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(electricWindows)
                  ? electricWindows
                  : noData}
              </span>
            </div>): null}
            {navigationBig === 'Ja'  && !_.isEmpty(navigationBig) ? (  <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Navigatie groot
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(navigationBig)
                  ? navigationBig
                  : noData}
              </span>
            </div>): null}
            {navigationSmall === 'Ja' && !_.isEmpty(navigationSmall)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Navigatie klein
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(navigationSmall)
                  ? navigationSmall
                  : noData}
              </span>
            </div>): null}
            {startStop === 'Ja' && !_.isEmpty(startStop) ? (  <div className="opties-container-body-columns-info-last-block">
              <span className="opties-container-body-columns-info-block-title">
                Start/Stop
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(startStop) ? startStop : noData}
              </span>
            </div>) : null}
          </div>
          <div className="opties-container-body-columns">
          {powerSteering === 'Ja' && !_.isEmpty(powerSteering)  ? (  <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Stuurbekrachtiging
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(powerSteering)
                  ? powerSteering
                  : noData}
              </span>
            </div>
            ) : null}
            {roofRail === 'Ja' && !_.isEmpty(roofRail)  ? (<div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Dakrail
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(roofRail) ? roofRail : noData}
              </span>
            </div>) : null}
            {ledXenon === 'Ja' && !_.isEmpty(ledXenon) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Verlichting Led/Xenon
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(ledXenon) ? ledXenon : noData}
              </span>
            </div> ) : null}
            {alloywheels === 'Ja' && !_.isEmpty(alloywheels) ? (  <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Lichtmetalen wielen
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(alloywheels) ? alloywheels : noData}
              </span>
            </div> ) : null}
            {airsuspension === 'Ja' && !_.isEmpty(airsuspension)  ? (  <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Luchtvering
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(airsuspension)
                  ? airsuspension
                  : noData}
              </span>
            </div> ) : null}
             {foglights === 'Ja' && !_.isEmpty(foglights)  ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Mistlampen
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(foglights) ? foglights : noData}
              </span>
            </div> ) : null}
              {pdc === 'Ja' && !_.isEmpty(pdc) ? (<div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Parkeersensoren
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(pdc) ? pdc : noData}
              </span>
            </div> ) : null}
             {sunroof === 'Ja' && !_.isEmpty(sunroof) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Schuif/pano dak
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(sunroof) ? sunroof : noData}
              </span>
            </div> ) : null}
             {xenon === 'Ja' && !_.isEmpty(xenon) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Verlichting Xenon
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(xenon) ? xenon : noData}
              </span>
            </div> ) : null}
             {led === 'Ja' && !_.isEmpty(led) ? ( <div className="opties-container-body-columns-info-block">
              <span className="opties-container-body-columns-info-block-title">
                Verlichting Led
              </span>
              <span className="opties-container-body-columns-info-block-value">
                {!_.isEmpty(led) ? led : "-"}
              </span>
            </div> ) : null}
           <div className="opties-container-body-columns-info-last-block">
              {/* <span className="opties-container-body-columns-info-block-title">
                Naam van de optie
              </span>
              <span className="opties-container-body-columns-info-block-value"></span> */}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default OptiesContainer;
