import React from 'react'
import {ReactComponent as Logo} from '../../assets/Logo.svg'
import './mlHeaderlogo.style.css'



export default function MlHeaderlogo() {
    return (
     <div className="header-logo">
      
        
        <Logo/>

    </div>
    )
}
