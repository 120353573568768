import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dateFormat from "dateformat";
import "./style.css";
import "./imageSlider.css";
import WonBidHeader from "./TopHeader/wonbidHeader";
import MiddleHeader from "./MiddleHeader/middleHeader";
import QuoteBreakdownComponent from "./CarPassportBody/WonContainer/QuoteBreakdownComponent";
import ResistrationContainer from "./CarPassportBody/WonContainer/ResistrationContainer";
import TransportationContainer from "./CarPassportBody/WonContainer/TransportationContainer";
import ReclaimContainer from "./CarPassportBody/WonContainer/ReclaimContainer";
import { CarpassportDetails } from "./CarpassportDetails";
import { TabPanel } from "./MiddleHeader/PlaceBidSubHeaders/TabPanel";
import _ from "lodash";

function WonCarPassport(props) {
  const { state } = useLocation();
  const [selectedVehicleId, setSelectedVehicleId] = useState({});
  const { stateValue, vehicleDetail, onPrevious, onNext,isFromMyBid=false } = props;
  const getToken = localStorage.getItem("Token");
  const [activeTab, setActiveTab] = useState(0);
  const updateActiveTab = (val) => {
    setActiveTab(val);
  };

  useEffect(() => {
    
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    let vehicleID = props.isNavigationNeeded
      ? _.get(vehicleDetail, "itemInfo.id")
      : state.vehicleId;
    fetch(
      hostURL + `/api/auction/VehicleById/${vehicleID}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setSelectedVehicleId(data);
      }).catch(err=>{
        console.log(err);
      });
  }, []);

  let startBiddingDate = dateFormat(state?.openForBidding, "dd-mm-yy");
  let startBiddinghours = dateFormat(state?.openForBidding, "HH");
  let startBiddingminute = dateFormat(state?.openForBidding, "mm");

 

  let checkDamageStatus = (selectedVehicle) => {
    if (selectedVehicle) {
      let hasTechnicalDamage =
        selectedVehicle.technicalDamages &&
        selectedVehicle.technicalDamages.length > 0;
      let hasOpticalDamage =
        selectedVehicle.opticalDamages &&
        selectedVehicle.opticalDamages.length > 0;
      return hasTechnicalDamage || hasOpticalDamage;
    }
  };

  return (
    <>
      <div className="carpassport-container-wrapper wonIndex">
        <div>
          <div
            className="carpassport-container-wrapper-wonbidHeader"
            data-testid="carpassport-container"
          >
              
          {props.isNavigationNeeded ? (
            <WonBidHeader
              accesstoken={stateValue.accesstoken}
              userid={stateValue.userid}
              vehicleId={_.get(vehicleDetail, "itemInfo.id")}
              catalogID={_.get(vehicleDetail, "catalogID")}
              lotID={_.get(vehicleDetail, "lotId")}
              bidStatus={_.get(vehicleDetail, "status")}
              openForBidding={_.get(vehicleDetail, "openForBidding")}
              startBiddingDate={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "dd-mm-yy"
              )}
              startBiddinghours={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "HH"
              )}
              startBiddingminute={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "MM"
              )}
              openForBiddingminute={dateFormat(
                _.get(vehicleDetail, "openForBidding"),
                "MM"
              )}
              expectedDeliveryDate={
                selectedVehicleId.expectedDeliveryDate !== undefined || null
                  ? selectedVehicleId.expectedDeliveryDate
                  : ""
              }
              licencePlate={_.get(vehicleDetail, "itemInfo.licenceplate")}
              modelType={_.get(vehicleDetail, "itemInfo.modelType")}
              brand={_.get(vehicleDetail, "itemInfo.brand")}
              enddayofbidding={_.get(
                vehicleDetail,
                "auctionParameters.closedForBidding"
              )}
              selectedVehicleId={vehicleDetail}
              transmission={_.get(vehicleDetail, "itemInfo.transmission")}
              minBid={_.get(vehicleDetail, "priceSettings.minBid")}


              isNavigationNeeded={
                props.isNavigationNeeded ? props.isNavigationNeeded : false
              }
              vehiclesList={props.vehiclesList}
              bidAmount={_.get(vehicleDetail, "bidInfoPage.bidAmount")}
              bidId={_.get(vehicleDetail, "bidID")}
              bidInfoPage={_.get(vehicleDetail, "bidInfoPage")}
              isFromMyBid={isFromMyBid}

           
            />
          ) : (
            <WonBidHeader
              bidAmount={state?.BidAmount}
              lotID={state?.lotID}
              bidId={state?.bidId !== null ? state?.bidId : null}
              accesstoken={state?.accesstoken}
              userid={state?.userid}
              catalogID={state?.catalogID}
              bidStatus={state?.bidStatus}
              openForBidding={state?.OpenForBidding}
              startBiddingDate={startBiddingDate}
              startBiddinghours={startBiddinghours}
              openForBiddingminute={startBiddingminute}
              expectedDeliveryDate={
                selectedVehicleId.expectedDeliveryDate !== undefined
                  ? selectedVehicleId.expectedDeliveryDate
                  : ""
              }
              licencePlate={selectedVehicleId.licenceplate}
              modelType={state?.modelType}
              brand={state?.brand}
              enddayofbidding={state.enddayofbidding}
              selectedVehicleId={selectedVehicleId}
              minBid={state.minBid}
              isNavigationNeeded={
                props.isNavigationNeeded ? props.isNavigationNeeded : false
              }
              vehiclesList={props.vehiclesList}
              isFromMyBid={isFromMyBid}
            />)}
          </div>

          <div className="carpassport-container-wrapper-won-middleHeader">
            <MiddleHeader
              licencePlate={selectedVehicleId?.licenceplate}
              model={selectedVehicleId?.model}
              brand={selectedVehicleId?.brand}
              modelType={selectedVehicleId?.modelType}
              coachType={selectedVehicleId?.coachType}
              fuelType={selectedVehicleId?.fuelType}
              milage={selectedVehicleId?.mileage}
              mileageUnits={selectedVehicleId?.mileageUnits}
              modelVersionFrom={selectedVehicleId?.modelVersionFrom}
              transmission={selectedVehicleId?.transmission}
              jaar={selectedVehicleId?.firstRegistrationInt}
              hadDamage={checkDamageStatus(selectedVehicleId)}
              updateActivTabFn={updateActiveTab}
              onPrevious={() => onPrevious()}
            onNext={() => onNext()}
            indexDetails={props?.indexDetails}
            isNavigationNeeded={
              props.isNavigationNeeded ? props.isNavigationNeeded : false
            }
            />
          </div>
        </div>
        <TabPanel value={activeTab} index={0}>
          <div className="carpassport-won-container-wrapper-main">
            <div className="wonHeaderContent">
              <h2 style={{ margin: "10px 0" }}>
              Gefeliciteerd, deze auto is voor U!
              </h2>
              <span>
              Gefeliciteerd met het winnende bod! Dit is een toelichting van het proces om het gekochte voertuig op je locatie te krijgen. De afhandeling loopt direct tussen jou en onze partner Autoplatform Nederland, waarbij onderstaande stappen van toepassing zijn. Autoplatform Nederland zet de stappen in gang.
              </span>
            </div>
            <div className="carpassport-won-container-wrapper-first">
              <QuoteBreakdownComponent bidAmount={state?.BidAmount} />
              <ResistrationContainer />
            </div>
            <div className="carpassport-won-container-wrapper-first">
              {/* <br/> */}
              <TransportationContainer />
              {/* <ReclaimContainer /> */}
            </div>
          </div>
          {/** carpassport Details */}
          <CarpassportDetails {...selectedVehicleId} />
          {/** carpassport Details ends */}
        </TabPanel>
      </div>
    </>
  );
}

export default WonCarPassport;
