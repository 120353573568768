import React from 'react';
import LaptopImg from '../../images/desktop_image.png';
import './mlHero.style.css';

export default function MlHero() {
  return (
    <div className='hero-container ' role='img'>
      <div className='hero-main-container'>
        <div className='hero-laptop-image'>
          <img
            className='laptop-image'
            src={LaptopImg}
            alt='laptopIimage'
          ></img>
        </div>

        <div className='hero-heading'>
          <h1 className='mlcontentheader-container-title'>
            Autoveiling exclusief voor BOVAG leden
          </h1>
          <p className='step_header_description'>
            Meld je nu aan en krijg toegang tot deze gesloten, exclusieve
            veiling voor BOVAG leden van auto’s die op viaBOVAG.nl. zijn
            verkocht door consumenten.
          </p>
        </div>
      </div>
    </div>
  );
}
