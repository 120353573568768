

import React from 'react'
import './footerstyle.css'
import Footer1Image from '../../images/ml-3-step-images/footer1.png';

import MapImage from '../../images/ml-3-step-images/map.png';
import EmailImage from '../../images/ml-3-step-images/email.png';
import LogoImage from '../../images/logo.png'

export default function Footercomponent() {
    return (
        <div className="footer-container">
            <div className="footer-image-box">
                <img className="footer-image" src={Footer1Image} alt="footerImage"></img>
            </div>
            <div className="contact-details">
                
                   
                         <div className="contact-1">
                         <div className="location-adjustment-1">
                      <span className="location-icon"><img src={MapImage} alt="locationIcon"></img></span>
                      </div>
                             <span className="contact-subdetail"> Postadres</span>
                              <span className="address">Postbus7110</span>
                            <span className="address">6503 GP Nijmegen</span>
                       
                   
                </div>
        
              
                 <div className="contact-2">
                 <div className="location-adjustment-2">
                    <span className="location-icon"><img src={MapImage} alt="locationIcon"></img></span>
                    </div>
                    <span className="contact-subdetail"> Bezoekadres</span>
                   <span className="address">Takenhofplein 2,</span>
                   <span className="address">6503 SZ Nijmegen</span>
              
            
          </div>
               
               
                <div className="contact-3">
                <div className="location-adjustment-3">
                <span className="location-icon"><img src={EmailImage} alt="locationIcon"></img></span>
                </div>
                    <span className="contact-subdetail email-detail">  E-mail & Telefoon</span>
                   <span className="address">024-3567823</span>
                   <span className="address">business@viabovemij.nl</span>
               
               
                </div>
                <div className="logo-img-box">
                   <img className="logo-img"src={LogoImage} alt="LogoImage"></img>

                </div>

            </div>
            <div className="footer-copyright">
                <span > &copy; copyright 2021 ,Bovemij </span>
                <span>  Privacyverkaling </span>

            </div>
            
        </div>
    )
}
