import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import _ from "lodash";
import "./style.css";
import "./imageSlider.css";
import TopHeader from "./TopHeader/topHeader";
import MiddleHeader from "./MiddleHeader/middleHeader";

import { CarpassportDetails } from "./CarpassportDetails";

function CarPassport(props) {
  const { stateValue, vehicleDetail, onPrevious, onNext } = props;
  const getToken = localStorage.getItem("Token");
  const [selectedVehicleId, setSelectedVehicleId] = useState({});

  useEffect(() => {
    const requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    const hostURL = process.env.REACT_APP_SERVER_URL;
    fetch(
      hostURL +
        `/api/auction/VehicleById/${_.get(vehicleDetail, "itemInfo.id")}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (!_.isEmpty(data)) {
          setSelectedVehicleId(data);
        }
      }).catch(err=>{
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let startBiddingDate = dateFormat(state.OpenForBidding, "dd-mm-yy");
  // let startBiddinghours = dateFormat(state.OpenForBidding, "HH");
  // let startBiddingminute = dateFormat(state.OpenForBidding, "MM");

  let checkDamageStatus = (selectedVehicle) => {
    if (selectedVehicle) {
      let hasTechnicalDamage =
        selectedVehicle.technicalDamages &&
        selectedVehicle.technicalDamages.length > 0;
      let hasOpticalDamage =
        selectedVehicle.opticalDamages &&
        selectedVehicle.opticalDamages.length > 0;
      return hasTechnicalDamage || hasOpticalDamage;
    }
  };

  return (
    <div className="carpassport-container-wrapper">
      <div>
        <div
          class="carpassport-container-wrapper-topHeader"
          data-testid="carpassport-container"
        >
          <TopHeader
            accesstoken={stateValue.accesstoken}
            userid={stateValue.userid}
            vehicleId={_.get(vehicleDetail, "itemInfo.id")}
            catalogID={_.get(vehicleDetail, "catalogID")}
            lotID={_.get(vehicleDetail, "lotId")}
            bidStatus={_.get(vehicleDetail, "status")}
            openForBidding={_.get(vehicleDetail, "openForBidding")}
            startBiddingDate={dateFormat(
              _.get(vehicleDetail, "openForBidding"),
              "dd-mm-yy"
            )}
            startBiddinghours={dateFormat(
              _.get(vehicleDetail, "openForBidding"),
              "HH"
            )}
            startBiddingminute={dateFormat(
              _.get(vehicleDetail, "openForBidding"),
              "MM"
            )}
            expectedDeliveryDate={
              selectedVehicleId.expectedDeliveryDate !== undefined || null
                ? selectedVehicleId.expectedDeliveryDate
                : ""
            }
            licencePlate={_.get(vehicleDetail, "itemInfo.licenceplate")}
            modelType={_.get(vehicleDetail, "itemInfo.modelType")}
            brand={_.get(vehicleDetail, "itemInfo.brand")}
            enddayofbidding={_.get(
              vehicleDetail,
              "auctionParameters.closedForBidding"
            )}
            selectedVehicleIdProp={vehicleDetail}
            transmission={_.get(vehicleDetail, "itemInfo.transmission")}
            minBid={_.get(vehicleDetail, "priceSettings.minBid")}
            isNavigationNeeded={props.isNavigationNeeded? props.isNavigationNeeded :false}
            vehiclesList = {props.vehiclesList}

          />
        </div>

        <div className="carpassport-container-wrapper-middleHeader">

          <MiddleHeader
            licencePlate={selectedVehicleId?.licenceplate}
            model={selectedVehicleId?.model}
            brand={selectedVehicleId?.brand}
            modelType={selectedVehicleId?.modelType}
            coachType={selectedVehicleId?.coachType}
            fuelType={selectedVehicleId?.fuelType}
            milage={selectedVehicleId?.mileage}
            mileageUnits={selectedVehicleId?.mileageUnits}
            modelVersionFrom={selectedVehicleId?.modelVersionFrom}
            transmission={selectedVehicleId?.transmission}
            jaar={selectedVehicleId?.firstRegistrationInt}
            hadDamage={checkDamageStatus(selectedVehicleId)}
            onPrevious={() => onPrevious()}
            onNext={() => onNext()}
            indexDetails={props?.indexDetails}
            isNavigationNeeded={props.isNavigationNeeded? props.isNavigationNeeded :false}
          />
        </div>
      </div>
      {/** carpassport Details */}
      <CarpassportDetails {...selectedVehicleId} />
      {/** carpassport Details ends */}
    </div>
  );
}

export default CarPassport;
