import React from 'react'
import './mlThankyou.css'

function MlThankYouPage() {
    return (
        <div className='thankyou-container'>
            <h1 className="thankyou-mlpage"> Bedankt ! </h1>
<div className="ml-form-thank-you-image">
            <svg width="164" height="149" viewBox="0 0 164 149" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5034:153666)">
<path d="M12.7398 136.737C13.679 137.088 14.6875 137.176 15.6679 136.992C16.6484 136.809 17.5679 136.359 18.3378 135.686C20.2985 133.933 20.9134 131.046 21.4135 128.371L22.893 120.461L19.7955 122.733C17.5679 124.367 15.2902 126.053 13.748 128.42C12.2057 130.786 11.5329 134.017 12.7717 136.578" fill="#C6CBD4"/>
<path d="M13.218 146.864C12.8282 143.838 12.4271 140.774 12.7012 137.715C12.9441 134.999 13.722 132.347 15.3056 130.172C16.1461 129.019 17.1689 128.032 18.328 127.254C18.6302 127.051 18.9084 127.561 18.6075 127.763C16.6019 129.113 15.0506 131.107 14.1773 133.457C13.2129 136.07 13.058 138.919 13.2241 141.695C13.3245 143.373 13.5379 145.041 13.7522 146.707C13.7692 146.782 13.7586 146.862 13.7227 146.929C13.6868 146.996 13.6282 147.047 13.5588 147.07C13.488 147.09 13.4125 147.08 13.3488 147.041C13.285 147.003 13.2381 146.939 13.2182 146.864L13.218 146.864Z" fill="#121212" fill-opacity="0.4"/>
<path d="M16.0244 142.011C16.4272 142.664 16.9809 143.195 17.6321 143.553C18.2834 143.911 19.0103 144.084 19.7428 144.056C21.6256 143.961 23.1946 142.562 24.6069 141.233L28.7851 137.303L26.02 137.162C24.0314 137.061 21.9915 136.966 20.0974 137.62C18.2033 138.274 16.4565 139.845 16.1102 141.934" fill="#C6CBD4"/>
<path d="M12.116 148.603C13.9929 145.065 16.1698 141.133 20.0598 139.876C21.1414 139.528 22.2754 139.404 23.4009 139.509C23.7557 139.542 23.6671 140.124 23.3131 140.092C21.4264 139.925 19.5434 140.455 17.981 141.594C16.4775 142.684 15.3068 144.2 14.3161 145.807C13.7092 146.792 13.1656 147.818 12.622 148.843C12.4483 149.171 11.9403 148.934 12.116 148.603Z" fill="#121212" fill-opacity="0.4"/>
<path d="M162.297 104.93H58.8204C58.5015 104.93 58.1959 104.794 57.9704 104.554C57.7449 104.314 57.6181 103.988 57.6177 103.649V1.28119C57.6181 0.941537 57.7449 0.615917 57.9704 0.375743C58.1959 0.135569 58.5015 0.000444017 58.8204 0H162.297C162.616 0.000444017 162.922 0.135569 163.147 0.375743C163.373 0.615917 163.5 0.941537 163.5 1.28119V103.649C163.5 103.988 163.373 104.314 163.147 104.554C162.922 104.794 162.616 104.929 162.297 104.93V104.93Z" fill="white"/>
<path d="M162.297 104.93H58.8204C58.5015 104.93 58.1959 104.794 57.9704 104.554C57.7449 104.314 57.6181 103.988 57.6177 103.649V1.28119C57.6181 0.941537 57.7449 0.615917 57.9704 0.375743C58.1959 0.135569 58.5015 0.000444017 58.8204 0H162.297C162.616 0.000444017 162.922 0.135569 163.147 0.375743C163.373 0.615917 163.5 0.941537 163.5 1.28119V103.649C163.5 103.988 163.373 104.314 163.147 104.554C162.922 104.794 162.616 104.929 162.297 104.93V104.93ZM58.8204 0.511468C58.6293 0.51191 58.4461 0.592973 58.311 0.736913C58.1759 0.880853 58.0998 1.07595 58.0994 1.27951V103.649C58.0998 103.852 58.1759 104.047 58.311 104.191C58.4461 104.335 58.6293 104.416 58.8204 104.417H162.297C162.488 104.416 162.672 104.335 162.807 104.191C162.942 104.047 163.018 103.852 163.018 103.649V1.28119C163.018 1.07763 162.942 0.882534 162.807 0.738594C162.671 0.594654 162.488 0.513591 162.297 0.513149L58.8204 0.511468Z" fill="#D3E3F7"/>
<path d="M107.715 26.6016C107.499 26.602 107.291 26.6938 107.139 26.8568C106.986 27.0199 106.9 27.2409 106.9 27.4714C106.9 27.7018 106.986 27.9228 107.139 28.0859C107.291 28.249 107.499 28.3408 107.715 28.3412H152.519C152.735 28.3408 152.943 28.249 153.095 28.0859C153.248 27.9228 153.334 27.7018 153.334 27.4714C153.334 27.2409 153.248 27.0199 153.095 26.8568C152.943 26.6938 152.735 26.602 152.519 26.6016H107.715Z" fill="#D3E3F7"/>
<path d="M107.715 31.8203C107.499 31.8209 107.292 31.9128 107.139 32.0758C106.986 32.2389 106.9 32.4598 106.9 32.6901C106.9 32.9205 106.986 33.1414 107.139 33.3044C107.292 33.4675 107.499 33.5594 107.715 33.5599H130.588C130.804 33.5594 131.012 33.4675 131.164 33.3044C131.317 33.1414 131.403 32.9205 131.403 32.6901C131.403 32.4598 131.317 32.2389 131.164 32.0758C131.012 31.9128 130.804 31.8209 130.588 31.8203H107.715Z" fill="#D3E3F7"/>
<path d="M68.5553 59.8867C68.339 59.8873 68.1319 59.9792 67.9792 60.1422C67.8265 60.3053 67.7407 60.5262 67.7407 60.7565C67.7407 60.9869 67.8265 61.2078 67.9792 61.3708C68.1319 61.5339 68.339 61.6258 68.5553 61.6263H152.563C152.779 61.6258 152.986 61.5339 153.139 61.3708C153.292 61.2078 153.377 60.9869 153.377 60.7565C153.377 60.5262 153.292 60.3053 153.139 60.1422C152.986 59.9792 152.779 59.8873 152.563 59.8867H68.5553Z" fill="#D3E3F7"/>
<path d="M68.5553 65.1055C68.339 65.106 68.1319 65.1979 67.9792 65.361C67.8265 65.524 67.7407 65.745 67.7407 65.9753C67.7407 66.2056 67.8265 66.4265 67.9792 66.5896C68.1319 66.7526 68.339 66.8445 68.5553 66.8451H130.632C130.849 66.8445 131.056 66.7526 131.208 66.5896C131.361 66.4265 131.447 66.2056 131.447 65.9753C131.447 65.745 131.361 65.524 131.208 65.361C131.056 65.1979 130.849 65.106 130.632 65.1055H68.5553Z" fill="#D3E3F7"/>
<path d="M68.5553 70.207C68.339 70.2076 68.1319 70.2995 67.9792 70.4625C67.8265 70.6256 67.7407 70.8465 67.7407 71.0768C67.7407 71.3072 67.8265 71.5281 67.9792 71.6911C68.1319 71.8542 68.339 71.9461 68.5553 71.9466H152.563C152.779 71.9461 152.986 71.8542 153.139 71.6911C153.292 71.5281 153.377 71.3072 153.377 71.0768C153.377 70.8465 153.292 70.6256 153.139 70.4625C152.986 70.2995 152.779 70.2076 152.563 70.207H68.5553Z" fill="#D3E3F7"/>
<path d="M68.5553 75.4258C68.339 75.4263 68.1319 75.5182 67.9792 75.6813C67.8265 75.8443 67.7407 76.0653 67.7407 76.2956C67.7407 76.5259 67.8265 76.7468 67.9792 76.9099C68.1319 77.073 68.339 77.1648 68.5553 77.1654H130.632C130.849 77.1648 131.056 77.073 131.208 76.9099C131.361 76.7468 131.447 76.5259 131.447 76.2956C131.447 76.0653 131.361 75.8443 131.208 75.6813C131.056 75.5182 130.849 75.4263 130.632 75.4258H68.5553Z" fill="#D3E3F7"/>
<path d="M68.5553 80.6641C68.339 80.6646 68.1319 80.7565 67.9792 80.9196C67.8265 81.0826 67.7407 81.3035 67.7407 81.5339C67.7407 81.7642 67.8265 81.9851 67.9792 82.1482C68.1319 82.3112 68.339 82.4031 68.5553 82.4037H152.563C152.779 82.4031 152.986 82.3112 153.139 82.1482C153.292 81.9851 153.377 81.7642 153.377 81.5339C153.377 81.3035 153.292 81.0826 153.139 80.9196C152.986 80.7565 152.779 80.6646 152.563 80.6641H68.5553Z" fill="#D3E3F7"/>
<path d="M68.5553 85.8828C68.339 85.8834 68.1319 85.9753 67.9792 86.1383C67.8265 86.3014 67.7407 86.5223 67.7407 86.7526C67.7407 86.983 67.8265 87.2039 67.9792 87.3669C68.1319 87.53 68.339 87.6219 68.5553 87.6224H130.632C130.849 87.6219 131.056 87.53 131.208 87.3669C131.361 87.2039 131.447 86.983 131.447 86.7526C131.447 86.5223 131.361 86.3014 131.208 86.1383C131.056 85.9753 130.849 85.8834 130.632 85.8828H68.5553Z" fill="#D3E3F7"/>
<path d="M82.13 42C79.7566 42 77.4365 41.2963 75.4631 39.9777C73.4897 38.6591 71.9516 36.785 71.0434 34.5923C70.1351 32.3996 69.8974 29.9868 70.3605 27.659C70.8235 25.3312 71.9663 23.193 73.6446 21.5148C75.3228 19.8365 77.461 18.6936 79.7888 18.2306C82.1165 17.7676 84.5293 18.0052 86.7221 18.9134C88.9148 19.8217 90.7889 21.3598 92.1075 23.3331C93.4261 25.3065 94.1299 27.6266 94.1299 30C94.1263 33.1815 92.8609 36.2316 90.6112 38.4813C88.3616 40.7309 85.3115 41.9964 82.13 42Z" fill="#27AD7A"/>
<path d="M46.251 89.8284C46.2307 89.3723 46.1168 88.9264 45.9173 88.5223C45.7177 88.1181 45.4374 87.7655 45.0961 87.4892C44.7548 87.2129 44.3608 87.0197 43.9418 86.9232C43.5228 86.8267 43.0891 86.8292 42.6711 86.9306L38.7935 81.1094L34.8979 82.7568L40.4823 90.8955C40.6887 91.6322 41.1416 92.2627 41.7552 92.6674C42.3688 93.0721 43.1005 93.223 43.8116 93.0915C44.5226 92.9599 45.1637 92.5551 45.6133 91.9537C46.0629 91.3523 46.2898 90.5961 46.251 89.8284H46.251Z" fill="#A0616A"/>
<path d="M38.6939 89.3945L26.9609 73.1799L31.3572 58.4615C31.6793 54.7611 33.853 53.7278 33.9455 53.6856L34.0866 53.6211L37.9122 64.4887L35.1033 72.4678L41.9976 84.8196L38.6939 89.3945Z" fill="#27AD7A"/>
<path d="M79.4402 39.4137C79.0149 39.4705 78.6064 39.626 78.2436 39.8692C77.8807 40.1123 77.5724 40.4373 77.3402 40.8212C77.108 41.205 76.9576 41.6384 76.8997 42.0909C76.8418 42.5433 76.8777 43.0038 77.0049 43.4399L71.8564 48.0083L73.6995 52.0183L80.885 45.4581C81.5585 45.1819 82.1136 44.6521 82.4449 43.9691C82.7763 43.2861 82.8609 42.4973 82.6828 41.7523C82.5047 41.0072 82.0763 40.3577 81.4786 39.9267C80.881 39.4956 80.1557 39.3131 79.4402 39.4137Z" fill="#A0616A"/>
<path d="M79.6206 47.4714L65.3521 61.1864L51.2369 57.6552C47.7487 57.5991 46.6135 55.3704 46.5669 55.2754L46.4956 55.1306L56.371 50.2273L64.056 52.5936L75.0834 44.3164L79.6206 47.4714Z" fill="#27AD7A"/>
<path d="M54.9055 144.971L58.7392 144.971L60.5628 129.219L54.9048 129.219L54.9055 144.971Z" fill="#A0616A"/>
<path d="M66.1328 148.762L54.0842 148.762L54.084 143.803L62.7523 143.803C63.6488 143.803 64.5086 144.182 65.1426 144.857C65.7765 145.533 66.1327 146.449 66.1328 147.404L66.1328 148.762L66.1328 148.762Z" fill="#B3B3B3"/>
<path d="M39.583 144.971L43.4167 144.971L45.2405 129.219L39.5825 129.219L39.583 144.971Z" fill="#A0616A"/>
<path d="M50.8105 148.762L38.7619 148.762L38.7617 143.803L47.4301 143.803C47.874 143.803 48.3135 143.896 48.7237 144.077C49.1338 144.258 49.5064 144.523 49.8203 144.857C50.1342 145.192 50.3832 145.589 50.5531 146.026C50.723 146.463 50.8105 146.931 50.8105 147.404L50.8105 148.762Z" fill="#B3B3B3"/>
<path d="M38.2197 87.4727L38.5324 110.79L38.8453 141.436L45.7245 140.77L49.1644 98.4655L53.5422 140.77H60.6444L61.3597 98.1324L58.8581 88.8052L38.2197 87.4727Z" fill="#333333"/>
<path d="M51.6846 90.6066C44.1994 90.6072 37.3093 86.9983 37.2163 86.9488L37.139 86.9076L36.5108 70.8489C36.3287 70.2815 32.7419 59.0839 32.1344 55.5248C31.5189 51.9189 40.4392 48.7543 41.5224 48.3842L41.7683 45.4834L51.7645 44.3359L53.0315 48.0476L56.6177 49.4801C57.0243 49.6426 57.3608 49.9584 57.5634 50.3675C57.766 50.7767 57.8207 51.2508 57.7171 51.7L55.7239 60.3346L60.5919 88.9018L59.5439 88.9501C57.0318 90.1587 54.3205 90.6065 51.6846 90.6066Z" fill="#27AD7A"/>
<path d="M50.61 41.5586C54.0979 39.5275 55.3797 34.869 53.4731 31.1535C51.5664 27.4381 47.1933 26.0726 43.7055 28.1037C40.2176 30.1347 38.9358 34.7932 40.8424 38.5087C42.7491 42.2242 47.1222 43.5897 50.61 41.5586Z" fill="#A0616A"/>
<path d="M41.3282 42.2529C42.6999 43.8094 45.2472 42.9738 45.4259 40.8475C45.4399 40.6824 45.4389 40.5163 45.4229 40.3514C45.3306 39.4095 44.8198 38.5544 44.9421 37.5599C44.9698 37.3124 45.0564 37.0765 45.1936 36.8747C46.2867 35.3155 48.8525 37.572 49.884 36.1606C50.5165 35.2951 49.773 33.9324 50.2584 32.9651C50.899 31.6884 52.7966 32.3182 53.9866 31.619C55.3105 30.841 55.2314 28.6771 54.3598 27.3609C53.2969 25.7557 51.4334 24.8992 49.5931 24.7757C47.7527 24.6523 45.9251 25.1822 44.2069 25.8955C42.2548 26.7059 40.319 27.8259 39.1177 29.6545C37.6567 31.8782 37.5162 34.8677 38.2468 37.4679C38.6913 39.0497 40.2081 40.9819 41.3282 42.2529Z" fill="#2F2E41"/>
<path d="M91.9549 148.959H0.739411C0.675915 148.959 0.615016 148.932 0.570118 148.885C0.52522 148.837 0.5 148.772 0.5 148.704C0.5 148.637 0.52522 148.572 0.570118 148.524C0.615016 148.476 0.675915 148.449 0.739411 148.449H91.9549C92.0184 148.449 92.0793 148.476 92.1242 148.524C92.1691 148.572 92.1943 148.637 92.1943 148.704C92.1943 148.772 92.1691 148.837 92.1242 148.885C92.0793 148.932 92.0184 148.959 91.9549 148.959Z" fill="#CACACA"/>
<path d="M81.1834 34.3013C80.9719 34.3016 80.7681 34.2163 80.6133 34.0627L76.7451 30.2353C76.5825 30.0741 76.4867 29.8508 76.4786 29.6144C76.4705 29.3779 76.5509 29.1478 76.702 28.9744C76.8531 28.8011 77.0627 28.6987 77.2846 28.6899C77.5065 28.681 77.7227 28.7664 77.8856 28.9272L81.162 32.1692L87.6444 25.2638C87.7222 25.1804 87.8146 25.1142 87.9164 25.0689C88.0182 25.0236 88.1273 25.0002 88.2377 25C88.348 24.9998 88.4572 25.0228 88.5592 25.0676C88.6611 25.1125 88.7538 25.1784 88.8318 25.2615C88.9098 25.3446 88.9716 25.4433 89.0137 25.5519C89.0558 25.6605 89.0774 25.7769 89.0771 25.8944C89.0769 26.012 89.0549 26.1283 89.0124 26.2367C88.9699 26.3451 88.9077 26.4436 88.8293 26.5263L81.7761 34.0399C81.6983 34.1229 81.6059 34.1887 81.5042 34.2336C81.4025 34.2784 81.2935 34.3014 81.1834 34.3013Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_5034:153666">
<rect width="163" height="149" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>
</div>
<h2 className="ml-form-thank-you-content">Uw gegevens zijn succesvol verzonden</h2>

        </div>
    )
}

export default MlThankYouPage
