import React from "react";
import HeaderIcon from "../Icons/ML/headerIcon";
import DesktopImage from "../../images/ml-3-step-images/ml-landing-car-page.png";
import BackgrondImage from "../../images/backgroundimage_ml.png";
import "./landingPageComponent.css";

function LPHeroImageComponent(props) {
  return (
    <div className="lpHeroImage-container">
      {/* <HeaderIcon/> */}
      <img src={BackgrondImage} alt="BackgrondImage" />
      <div className="lpHeroImage-description-image">
        {/* <div className="lpHeroImage-laptop-image">
          <img src={DesktopImage} alt="DesktopImage" />
        </div> */}
        <div className="lpHeroImage-description">
          <h1 className="lpHeroImage-title">Welkom op viaBovemij.nl</h1>
          <h3 className="lpHeroImage-subtitle">
          Het nieuwe digitale platform voor mobiliteitsbedrijven
          </h3>
          {/* <div className="lpHeroImage-subtitle-description">
            <p>
              We are working hard to prepare for you the best experience and a
              more integrated portal, to help you to find the best occasions,
              manage your leads and sell more, with after sales service and much
              more.
            </p>
            <p>
              For now only a small group of partners is able to login and test
              this amazing leap into the future, if you are one of them, please
              login and enjoy!
            </p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LPHeroImageComponent;
