import React from "react";
import _ from 'lodash';
import dateFormat from "dateformat";

import "./AnderenContainer.css";

function AnderenContainer(props) {
  let firstRegistrationProp = props.firstRegistration;
  let modelVersionFrom = props.modelVersionFrom;
  let firstRegistrationDate = firstRegistrationProp?.split("T")[0];
  modelVersionFrom = modelVersionFrom?.split("T")[0];

  let firstRegistrationDateFormat = dateFormat(
    firstRegistrationProp,
    "dd-mm-yyyy"
  );
  let noData = "-";
  let modelVersionFromFormat = dateFormat(modelVersionFrom, "dd-mm-yyyy");
  let currentCoutryOfRegistation = props.currentCoutryOfRegistation;
  let firstRegistrationNat = dateFormat(
    props.firstRegistrationNat,
    "dd-mm-yyyy"
  );
  let firstRegistrationInt = dateFormat(
    props.firstRegistrationInt,
    "dd-mm-yyyy"
  );
  let apkExpirationdateNl = dateFormat(props.apkExpirationdateNl, "dd-mm-yyyy");
  let wasEverTaxiCab = 
  String(props.wasEverTaxiCab) === "true"
  ? "Ja"
  : String(props.wasEverTaxiCab) === "false"
  ? "Nee"
  : "Onbekend";
  let wasEverDriverEducationCar = 
  String(props.wasEverDriverEducationCar) === "true"
  ? "Ja"
  : String(props.wasEverDriverEducationCar) === "false"
  ? "Nee"
  : "Onbekend";
  let wasEverGovermentCar =
    String(props.wasEverGovermentCar) === "true"
      ? "Ja"
      : String(props.wasEverGovermentCar) === "false"
      ? "Nee"
      : "Onbekend";
  let hasWokStatusNl = String(props.hasWokStatusNl) === "true"
  ? "Ja"
  : String(props.hasWokStatusNl) === "false"
  ? "Nee"
  : "Onbekend";
  return (
    <div className="anderen-container-block">
      <div className="anderen-container-title">
        <h4>RDW registratie</h4>
      </div>

      {
      _.isEmpty(firstRegistrationDateFormat) && _.isEmpty(modelVersionFromFormat) ? (
        "Onbekend"
      ) : (
        <div className="anderen-container-body-block">
          <div className="financien-container-body-columns">
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Land van registratie
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(currentCoutryOfRegistation) 
                  ? currentCoutryOfRegistation
                  : noData}
              </span>
            </div>
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Eerste registratie nationaal
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(firstRegistrationNat)
                  ? firstRegistrationNat
                  : noData}
              </span>
            </div>
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Eerste registratie internationaal
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(firstRegistrationInt)
                  ? firstRegistrationInt
                  : noData}
              </span>
            </div>
            <div className="financien-container-body-columns-info-last-block">
              <span className="financien-container-body-columns-info-block-title">
                Vervaldatum APK
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(apkExpirationdateNl)
                  ? apkExpirationdateNl
                  : noData}
              </span>
            </div>
          </div>
          <div className="anderen-container-body-columns">
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Als taxi gebruikt
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(wasEverTaxiCab)
                  ? wasEverTaxiCab
                  : noData}
              </span>
            </div>
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Als leswagen gebruikt
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(wasEverDriverEducationCar)
                  ? wasEverDriverEducationCar
                  : noData}
              </span>
            </div>
            <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                Als overheidsvoertuig gebruikt
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(wasEverGovermentCar)
                  ? wasEverGovermentCar
                  : noData}
              </span>
            </div>
           <div className="financien-container-body-columns-info-block">
              <span className="financien-container-body-columns-info-block-title">
                WOK-status
              </span>
              <span className="financien-container-body-columns-info-block-value">
                {!_.isEmpty(hasWokStatusNl)
                  ? hasWokStatusNl
                  : noData}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AnderenContainer;
