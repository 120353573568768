import React from 'react';

function HeaderIcon(props) {
    return (
        <svg width="1920" height="537" viewBox="0 0 1920 537" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1126.75 468C1102.97 468.398 1086.46 478.235 1075.21 488.877C1074.43 489.613 1073.65 490.354 1072.86 491.097C1072.86 491.097 1061.69 501.213 1053.81 507.163C1028.48 526.273 1010.38 534.372 967.363 536.852L966 537H0V0L1920 0.000366211V468H1126.75Z" fill="url(#paint0_linear)"/>
        <defs>
        <linearGradient id="paint0_linear" x1="-3.00468e-07" y1="-9.48785e-08" x2="1731.34" y2="402.031" gradientUnits="userSpaceOnUse">
        <stop stop-color="#27AD7A"/>
        <stop offset="0.307292" stop-color="#298A97"/>
        <stop offset="1" stop-color="#2A69B2"/>
        </linearGradient>
        </defs>
        </svg>
    );
}

export default HeaderIcon;