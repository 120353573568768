import React from 'react'

function SlideNextIcon() {
    return (
        <div>
            
<svg width="7.41" height="12" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.97982 0.5L0.569824 1.91L5.14982 6.5L0.569824 11.09L1.97982 12.5L7.97982 6.5L1.97982 0.5Z" fill="#2A69B2"/>
</svg>

        </div>
    )
}

export default SlideNextIcon
