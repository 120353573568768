import React, { Fragment, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";
import NorunningupcomingauctionIcon from "../Icons/AuctionDashboard/norunningupcomingauctionIcon";
import MlThankYouPage from "./mlThankYouPage";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";

// import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutlined";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import "./mlSignup.style.css";
import {
  InputAdornment,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  // Tooltip
} from "@material-ui/core";
import { Formik, Form } from "formik";
const postcodeValidation =(value)=>{
  let withoutSpaceValue = value.replace(/ /g,'');
  if(withoutSpaceValue.length === 6){
    let numberFlag =  value.match(/([0-9])/g)?.length === 4;
    let letterFlag =  value.match(/([a-zA-Z])/g)?.length ===2;
    return numberFlag && letterFlag;
  }else{
    return false
  }
 }

const phoneRegExp = /^([0-9+ \\]{10})$/;
const nameString = /^([^0-9]*)$/;
const plaatString = /^[A-Za-z_]+$/;
const charnumString =  /^[A-Za-z0-9_ ]{4,30}$/;
// const postcodeString =  /^[0-9]{4}(""||[ ])+[a-zA-Z]{2}$/;
const kvknumberString = /^([0-9_]{8})$/;
const accountnumberString = /^[A-Za-z0-9]{18}$/
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const MlSignupForm = () => {
  const [fnamevalue, setFname] = useState("");
  const [lnamevalue, setLname] = useState("");
  const [emailvalue, setEmailvalue] = useState("");
  const [telephoneValue, setTelephoneValue] = useState("");
  const [adresValue, setAdresValue] = useState("");
  const [postcodeValue, setPostcode] = useState("");
  const [plaatsValue, setPlaats] = useState("");
  const [kvknummerValue, setKvkNummer] = useState("");
  const [kvK_locatie_nummer, setKvkLocateNummer] = useState("");
  const [emailadresbedrijfValue, setEmailadresBedrijfValue] = useState("");
  const [accountnumberValue, setAccountnumberValue] = useState("");
  const [belasting_nummer, setBelastingNummer] = useState("");
  const [RDW_nummer, setRDWNummer] = useState("");

  const [errorMessage, setError] = useState("");
  const [myBidStatus, setMyBidStatus] = useState([]);
  let hostURL = process.env.REACT_APP_SERVER_URL;
  let getToken = localStorage.getItem("Token");
  let getUserid = localStorage.getItem("userid");
  const disableSubmitButtonFlag =()=>{
    if (
      fnamevalue === "" ||
      lnamevalue === "" ||
      emailvalue === "" ||
      telephoneValue === "" ||
      adresValue === "" ||
      postcodeValue === "" || 
      plaatsValue === "" ||
      kvknummerValue === "" || 
      kvK_locatie_nummer ==="" ||
      accountnumberValue === "" ||
      belasting_nummer ==="" ||
      RDW_nummer==="" ||
      emailadresbedrijfValue === ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()

      //.required('firstName is required')
      .matches(nameString, " "),
    lastname: Yup.string()
      // .required('lastname is required')
      .matches(nameString, " "),
    email: Yup.string().matches(emailRegex, " "),
  
    companyName: Yup.string(),
    address: Yup.string().matches(charnumString, " "),
    phoneNumber: Yup.string().matches(phoneRegExp, " "),
    postcode: Yup.string().test("","",(value)=>{
      let validationFlag  = postcodeValidation(value);
     return validationFlag;
    }),
    plaats: Yup.string().matches(plaatString, " "),
    kvk_nummer: Yup.string().matches(kvknumberString, " "),
    emailadres_bedrijf: Yup.string().matches(emailRegex, " "),
    accountnumber: Yup.string().matches(accountnumberString, " "),
    belasting_nummer:Yup.string().required(" "),
    RDW_nummer:Yup.string().required(" "),
    kvK_locatie_nummer:Yup.string().required(" "),

  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onError = (data) => {
    if (
      fnamevalue === "" ||
      lnamevalue === "" ||
      emailvalue === "" ||
      telephoneValue === "" ||
      adresValue === "" ||
      postcodeValue === "" || 
      plaatsValue === "" ||
      kvknummerValue === "" || 
      accountnumberValue === "" ||
      emailadresbedrijfValue === ""
    ) {
      setError("Gelieve alle verplichte(*) velden in te vullen");
    } else {
      setError(null);
    }
  };

  const onSubmit = (data) => {
    let params = JSON.stringify(data, null, 2);
    let requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: params,
    };
    fetch(hostURL + `/api/MarkettingLanding/Marketing`, requestOptionsvalue)
      .then((response) => {
        let dataJson = response.json();
        setMyBidStatus(response.status);
        return dataJson;
      })
      .then((data) => console.log(data, "data on submit"));
  };

  let tooltipBlock = {};

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {},
  }));
  return (
    <Fragment>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          companyName: "",
          region: "",
          personalDataFlag: false,
          marketingCommunicationsFlag: false,
          phoneNumber: "",
          address: "",
          postcode:"",
          plaats: "",
          kvk_nummer: "",
          kvK_locatie_nummer:"",
          emailadres_bedrijf:"",
          accountnumber: ""
        }}
        onSubmit={(values, { setSubmitting }) => {
          let params = JSON.stringify(values, null, 2);
          let requestOptionsvalue = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(getToken),
            },
            body: params,
          };
          fetch(
            hostURL + `/api/MarkettingLanding/Marketing`,
            requestOptionsvalue
          )
            .then((response) => {
              let dataJson = response.json();
              setMyBidStatus(response.status);

              return dataJson;
            })
            .then((data) => console.log(data));
        }}
      >
        <div className="mlForm-container">
          {myBidStatus === 500 || myBidStatus === 401 ? (
            <div className="no-running-upcoming-auctions-parent-container">
              <div className="no-running-upcoming-auctions-container">
                <NorunningupcomingauctionIcon />
              </div>

              <span className="no-running-upcoming-auctions-container-span">
                Geen lopende of aanstaande veiling beschikbaar
              </span>
            </div>
          ) : myBidStatus === 200 ? (
            <div>
              <MlThankYouPage />
            </div>
          ) : (
            <div className="mlForm-header-feild1">
              <div className="mlForm-profile-form">
                <Box px={3} py={2}>
                  <div className="mlForm-header-title">
                    <Typography variant="h6" margin="dense">
                      Voorinschrijving / Aanmelden autoveiling
                    </Typography>
                  </div>

                  <h3 className="error-message">{errorMessage}</h3>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      
                        <TextField
                          required={true}
                          id="firstName"
                          name="firstName"
                          label="Naam"
                          // placeholder="Jane"
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("firstName")}
                          error={
                            !fnamevalue.match(nameString)
                              ? true
                              : false
                          }
                          value={fnamevalue}
                          onChange={(e) => setFname(e.target.value)}
                          InputProps={{
                            title:" ",
                            startAdornment: (
                              <InputAdornment>
                                {/* <HomeOutlinedIcon style={{color:"#90a0b7", width : "20px", height:"17px"}}/> */}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                                {!fnamevalue.match(nameString) ? (
                                <Tooltip title="Wij verwachten hier enkel letters" arrow >
                                  <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                  />
                                </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                      
                      <Typography variant="inherit" color="textSecondary">
                        {errors.firstName?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      
                        <TextField
                          required={true}
                          id="lastname"
                          name="lastname"
                          label="Achternaam"
                          type="text"
                          // placeholder="Doe"
                          fullWidth
                          margin="dense"
                          {...register("lastname")}
                          error={
                            !_.isEmpty(lnamevalue)  && !lnamevalue.match(nameString)
                              ? true
                              : false
                          }
                          value={lnamevalue}
                          onChange={(e) => setLname(e.target.value)}
                          InputProps={{
                            title:" ",
                            startAdornment: (
                              <InputAdornment>
                                {/* <HomeOutlinedIcon style={{color:"#90a0b7", width : "20px", height:"17px"}}/> */}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                                {
                                !_.isEmpty(lnamevalue) && !lnamevalue.match(nameString) ? (
                                  <Tooltip title="Wij verwachten hier enkel letters" arrow >

                                  <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                  />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                     
                      <Typography variant="inherit" color="textSecondary">
                        {errors.lastname?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    
                        <TextField
                          required={true}
                          id="email"
                          name="email"
                          label="Emailadres gebruiker"
                          // placeholder="abc@email.com"
                          type="email"
                          fullWidth
                          margin="dense"
                          {...register("email")}
                          error={
                            !_.isEmpty(emailvalue)  && !emailvalue.match(emailRegex)
                              ? true
                              : false
                          }
                          value={emailvalue}
                          onChange={(e) => setEmailvalue(e.target.value)}
                          InputProps={{
                            title:" ",
                            startAdornment: (
                              <InputAdornment>
                                {/* <HomeOutlinedIcon style={{color:"#90a0b7", width : "20px", height:"17px"}}/> */}
                              </InputAdornment>
                            ),
                            endAdornment: (
                            <Tooltip title="Het lijkt erop dat dit emailadres niet klopt" arrow >
                              <InputAdornment>
                                {
                                 !_.isEmpty(emailvalue)  && !emailvalue.match(emailRegex) ? (
                                  <ErrorOutlinedIcon
                                    style={{ fill: "#D15744" }}
                                  />
                                ) : null}
                              </InputAdornment>
                            </Tooltip>
                            ),
                          }}
                        />
                    
                      <Typography variant="inherit" color="textSecondary">
                        {errors.email?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        id="companyName"
                        name="companyName"
                        label="Bedrijfsnaam"
                        required={true}
                        // placeholder="ABC"
                        type="text"
                        fullWidth
                        margin="dense"
                        {...register("companyName")}
                        error={errors.companyName ? true : false}
                        InputProps={{
                          title:" ",
                          startAdornment: (
                            <InputAdornment>
                              {/* <HomeOutlinedIcon style={{color:"#90a0b7", width : "20px", height:"17px"}}/> */}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography variant="inherit" color="textSecondary">
                        {errors.companyName?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Mobiel nummer"
                          required={true}
                          // placeholder="06-8888-8888"
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("phoneNumber")}
                          value={telephoneValue}
                          onChange={(e) => setTelephoneValue(e.target.value)}
                          error={
                          
                            !_.isEmpty(telephoneValue) && !telephoneValue.match(phoneRegExp)
                              ? true
                              : false
                          }
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {
                               !_.isEmpty(telephoneValue) && !telephoneValue.match(phoneRegExp) ? (
                                <Tooltip title="Het nummer is te kort" arrow >
                                  <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                  />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                   
                      <Typography variant="inherit" color="textSecondary">
                        {errors.phoneNumber?.message}
                      </Typography>
                    </Grid>

                    {/* neew fields starts here */}
                    <Grid item xs={12} sm={6}>
                      
                        <TextField
                          required={true}
                          id="address"
                          name="address"
                          label="Adres"
                          // placeholder="Jane"
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("address")}
                          error={!_.isEmpty(adresValue)  &&
                             !adresValue.match(charnumString)
                              ? true
                              : false
                          }
                          value={adresValue}
                          className="textBoxMLForm addressTextBox"
                          onChange={(e) => setAdresValue(e.target.value)}
                          InputProps={{
                            title:" ",
                            startAdornment: (
                              <InputAdornment>
                                {/* <HomeOutlinedIcon style={{color:"#90a0b7", width : "20px", height:"17px"}}/> */}
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                                {!_.isEmpty(adresValue)  &&
                                !adresValue.match(charnumString) ? (
                                <Tooltip title="Het lijkt erop dat dit adres niet klopt" arrow >
                                  <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                  />
                                </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                    
                      <Typography variant="inherit" color="textSecondary">
                        {errors.address?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                   
                        <TextField
                          id="postcode"
                          name="postcode"
                          label="Postcode"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("postcode")}
                          value={postcodeValue}
                          className="textBoxMLForm"
                          onChange={(e) => setPostcode(e.target.value)} 
                          error={ !_.isEmpty(postcodeValue) && !postcodeValidation (postcodeValue)? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {
                                 !_.isEmpty(postcodeValue) && !postcodeValidation (postcodeValue)? (
                                <Tooltip title="Het lijkt erop dat deze postcode niet klopt" arrow >
                                  <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                  />
                                </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                  
                      <Typography variant="inherit" color="textSecondary">
                        {errors.postcode?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                  
                        <TextField
                          id="plaats"
                          name="plaats"
                          label="Plaats"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("plaats")}
                          value={plaatsValue}
                          className="textBoxMLForm"
                          onChange={(e) => setPlaats(e.target.value)}
                          error={
                            !_.isEmpty(plaatsValue) && !plaatsValue.match(plaatString)
                              ? true
                              : false
                          }
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                { !_.isEmpty(plaatsValue) && !plaatsValue.match(plaatString) ? (
                                  <Tooltip title="Plaatsnaam mag alleen uit letters betaan" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                   
                      <Typography variant="inherit" color="textSecondary">
                        {errors.plaats?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                     
                        <TextField
                          id="kvk_nummer"
                          name="kvk_nummer"
                          label="KvK nummer"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("kvk_nummer")}
                          value={kvknummerValue}
                          className="textBoxMLForm"
                          onChange={(e) => setKvkNummer(e.target.value)}
                          error={!_.isEmpty(kvknummerValue) && !kvknummerValue.match(kvknumberString)? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {!_.isEmpty(kvknummerValue) && !kvknummerValue.match(kvknumberString)? (
                                  <Tooltip title="Oeps, wij verwachten hier 8 getallen" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                  
                      <Typography variant="inherit" color="textSecondary">
                        {errors.kvk_nummer?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                     
                        <TextField
                          id="kvK_locatie_nummer"
                          name="kvK_locatie_nummer"
                          label="KvK locatie nummer"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("kvK_locatie_nummer")}
                          value={kvK_locatie_nummer}
                          className="textBoxMLForm"
                          onChange={(e) => setKvkLocateNummer(e.target.value)}
                          error={errors.kvK_locatie_nummer ? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {errors.kvK_locatie_nummer ? (
                                  <Tooltip title="" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />
                  
                      <Typography variant="inherit" color="textSecondary">
                        {errors.kvK_locatie_nummer?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          id="emailadres_bedrijf"
                          name="emailadres_bedrijf"
                          label="Emailadres bedrijf	"
                          required={true}
                          type="email"
                          fullWidth
                          margin="dense"
                          {...register("emailadres_bedrijf")}
                          value={emailadresbedrijfValue}
                          className="textBoxMLForm"
                          onChange={(e) => setEmailadresBedrijfValue(e.target.value)}
                          error={ !_.isEmpty(emailadresbedrijfValue) && !emailadresbedrijfValue.match(emailRegex) ? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {!_.isEmpty(emailadresbedrijfValue) && !emailadresbedrijfValue.match(emailRegex) ? (
                                  <Tooltip title="Het lijkt erop dat dit emailadres niet klopt" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />

                      <Typography variant="inherit" color="textSecondary">
                        {errors.emailadres_bedrijf?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          id="accountnumber"
                          name="accountnumber"
                          label="IBAN"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("accountnumber")}
                          value={accountnumberValue}
                          className="textBoxMLForm"
                          onChange={(e) => setAccountnumberValue(e.target.value)}
                          error={ !_.isEmpty(accountnumberValue) && !accountnumberValue.match(accountnumberString) ? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                { !_.isEmpty(accountnumberValue) && !accountnumberValue.match(accountnumberString) ? (
                                  <Tooltip title="Het lijkt erop dat dit rekeningnummer niet klopt" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />

                      <Typography variant="inherit" color="textSecondary">
                        {errors.IBAN?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          id="belasting_nummer"
                          name="belasting_nummer"
                          label="BTW nummer"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("belasting_nummer")}
                          value={belasting_nummer}
                          className="textBoxMLForm"
                          onChange={(e) => setBelastingNummer(e.target.value)}
                          error={errors.belasting_nummer ? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {errors.belasting_nummer ? (
                                  <Tooltip title="" arrow >
                                    <ErrorOutlinedIcon
                                      style={{
                                        fill: "#D15744",
                                        color: "#D15744",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />

                      <Typography variant="inherit" color="textSecondary">
                        {errors.belasting_nummer?.message}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                          id="RDW_nummer"
                          name="RDW_nummer"
                          label="RDW nummer"
                          required={true}
                          type="text"
                          fullWidth
                          margin="dense"
                          {...register("RDW_nummer")}
                          value={RDW_nummer}
                          className="textBoxMLForm"
                          onChange={(e) => setRDWNummer(e.target.value)}
                          error={errors.RDW_nummer ? true : false}
                          InputProps={{
                            title:" ",
                            endAdornment: (
                              <InputAdornment>
                                {errors.RDW_nummer ? (
                                  <Tooltip title="" arrow >
                                    <ErrorOutlinedIcon
                                    style={{
                                      fill: "#D15744",
                                      color: "#D15744",
                                    }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </InputAdornment>
                            ),
                          }}
                        />

                      <Typography variant="inherit" color="textSecondary">
                        {errors.RDW_nummer?.message}
                      </Typography>
                    </Grid>
                  </Grid>
                  <div className="ml-form-signup-submit-button">
                    {/* <Grid item xs={12} sm={6}></Grid>
                  <Grid item xs={12} sm={6}> */}
                    {/* <Box mt={3}> */}
                      <Button
                        className="submitBtn"
                        variant="contained"
                        color="primary"
                        disabled={disableSubmitButtonFlag()}
                        onClick={handleSubmit(onSubmit, onError)}
                      >
                        Indienen
                      </Button>
                      {/*  */}
                    {/* </Box> */}
                    {/* </Grid> */}
                  </div>
                </Box>
              </div>
            </div>
          )}
        </div>
      </Formik>
    </Fragment>
  );
};

export default MlSignupForm;
