import React from 'react';
import './landingPageComponent.css';
import Footer1Image from '../../images/ml-3-step-images/footer1.png';
import MapImage from '../../images/ml-3-step-images/map.png';
import EmailImage from '../../images/ml-3-step-images/email.png';
import LogoImage from '../../images/logo.png'
import { Link } from 'react-router-dom';

function LPFooterfirstComponent(props) {
    return (
        <div className="lpFooterfirst-container">
            
        <div className="lpFooterlast-footer-container">
            <div className="lpFooterlast-footer-container-copyright">© Copyright 2021, Bovemij</div>
            <div className="lpFooterlast-footer-container-footermenu">
                <span><Link to="/Privacyverklaring">Privacyverklaring</Link></span>
                
            </div>
        </div>
        </div>
    );
}

export default LPFooterfirstComponent;