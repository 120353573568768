import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import "./mybids-table.css";
import UpdateButton from "../../atoms/UpdateButton/updatebutton";
import MybidsTimer from "./mybids-timer";
import CancelIcon from "../Icons/cancelIcon";
import NorunningbidsIcon from "../Icons/AuctionDashboard/norunningbidsIcon";
import { Table, Modal, Button } from "antd";
import moment from "moment";
import dateFormat from "dateformat";
import localization from "moment/locale/nl";
import { Spin } from "antd";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import LoaderIndex from "../LoaderComponent/LoaderIndex";

function DataTables(props) {
  let hostURL = process.env.REACT_APP_SERVER_URL;
  let testURL = process.env.REACT_APP_TESTAPI_URL;
  let [rowFilterdata, setRowFilterdata] = useState([]);
  let [rowstatus, setRowstatus] = useState();
  let [isModalVisible, setIsModalVisible] = useState(false);
  let getToken = localStorage.getItem("Token");
  let getUserid = localStorage.getItem("userid");
  let [tableLoaderFlag,setTableLoaderFlag] = useState(true);
  const [myBidStatus, setMyBidStatus] = useState([]);
  const [bitData, setBiData] = useState([]);
  const [timerCount, setTimerCount] = useState(0);
  const [isBitUpdate, setBitUpdate] = useState(false);
  const [bidId, updateBidId] = useState("");
  const [updatedBitRowData, setUpdatedBitRowData] = useState([]);
  const [cancelingBidId, setCancellingBidId] = useState();
  const { state } = useLocation();

  let vehList = state?.vehiclesList ? state?.vehiclesList : [];
  const [finalVehicleList, setFinalVehicleList] = useState(vehList);
  const [sortValue, setCurrentSortValue] = useState(
    state?.sortData
      ? state?.sortData
      : { field: "lastupdate", order: "descend" }
  );
  useEffect(() => {
    if (isBitUpdate) {
      const bitTimer = setTimeout(() => {
        setTimerCount(timerCount + 1);
        getAllBitInfo();
      }, 5000);
      if (timerCount === 3 || updatedBitRowData.length > 0) {
        clearTimeout(bitTimer);
        setBitUpdate(false);
      }

      return () => {
        clearTimeout(bitTimer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBitUpdate, timerCount]);

  useEffect(() => {
    getAllBitInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("sortData", JSON.stringify(sortValue));

    if (
      _.get(sortValue, "sortedListOrder") &&
      !_.isEmpty(bitData) &&
      finalVehicleList.length > 0
    ) {
      let sortedVehicleList = sortValue.sortedListOrder.map((bidId) => {
        return finalVehicleList.filter((vehicle) => vehicle.bidID === bidId)[0];
      });

      setFinalVehicleList(sortedVehicleList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, bitData]);

  const updateBitChange = useCallback((bitId) => {
    updateBidId(bitId);
    setBitUpdate(true);
    setTimerCount(0);
  }, []);

  let columns = [
    {
      title: "",
      dataIndex: "border",
    },
    {
      title: <span className="mybid-table-theader">Foto</span>,
      dataIndex: "photo",
      render: (text) => <span>{text}</span>,
    },
    {
      title: <span className="mybid-table-theader">Kenteken</span>,
      dataIndex: "plate",
    },
    {
      title: <span className="mybid-table-theader">Merk</span>,
      dataIndex: "brand",

      sortDirections: ["ascend", "descend", "ascend"],

      sorter: (a, b) =>
        a?.brand?.props?.children?.props?.children !== null
          ? (a?.brand?.props?.children?.props?.children).localeCompare(
              b?.brand?.props?.children?.props?.children
            )
          : null,
      defaultSortOrder:
        _.get(state, "sortData.field") === "brand"
          ? state.sortData.order
          : false,
    },
    {
      title: <span className="mybid-table-theader">Model</span>,
      dataIndex: "model",

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        a?.model?.props?.children?.props?.children !== null
          ? (a?.model?.props?.children?.props?.children).localeCompare(
              b?.model?.props?.children?.props?.children
            )
          : null,
      defaultSortOrder:
        _.get(state, "sortData.field") === "model"
          ? state.sortData.order
          : false,
    },
    {
      title: <span className="mybid-table-theader">Status</span>,
      dataIndex: "status",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) =>
        (a.status?.props?.children?.props?.children).localeCompare(
          b.status?.props?.children?.props?.children
        ),
      defaultSortOrder:
        _.get(state, "sortData.field") === "status"
          ? state.sortData.order
          : false,
    },
    {
      title: (
        <span className="mybid-table-theader">
          Startdatum <br />
          en -tijd
        </span>
      ),
      dataIndex: "start_date_time",

      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => {
        let t1 =
          a.start_date_time?.props?.children?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];

        let t2 =
          b.start_date_time?.props?.children?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];
        return new Date(t1) - new Date(t2);
      },
      defaultSortOrder:
        _.get(state, "sortData.field") === "start_date_time"
          ? state.sortData.order
          : false,
    },
    {
      title: (
        <span className="mybid-table-theader">
          Einddatum <br /> en -tijd
        </span>
      ),
      dataIndex: "end_date_time",
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => {
        let t1 =
          a.end_date_time?.props?.children?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];

        let t2 =
          b.end_date_time?.props?.children?.props?.children?.props?.children
            .map((y) => y?.props)
            .map((x) => x?.children)[0];

        return new Date(t1) - new Date(t2);
      },
      defaultSortOrder:
        _.get(state, "sortData.field") === "end_date_time"
          ? state.sortData.order
          : false,
    },

    {
      title: <span className="mybid-table-theader">Jouw bod</span>,
      dataIndex: "bidamount",
      sortDirections: ["ascend", "descend", "ascend"],

      sorter: (a, b) => {
        let a_value =
          a.bidamount?.props?.children?.props?.children.props.bidInfoPage
            .bidAmount;

        let b_value =
          b.bidamount?.props?.children?.props?.children.props.bidInfoPage
            .bidAmount;

        return a_value - b_value;
      },
      defaultSortOrder:
        _.get(state, "sortData.field") === "bidamount"
          ? state.sortData.order
          : false,
    },
    {
      title: <span className="mybid-table-theader"></span>,
      dataIndex: "timer",
    },

    {
      title: <span className="mybid-table-theader">Laatst bijgewerkt</span>,
      dataIndex: "lastupdate",
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: !_.get(state, "sortData.field")
        ? "descend"
        : _.get(state, "sortData.field") === "lastupdate"
        ? state.sortData.order
        : false,
      sorter: (a, b) => {
        let t1 = moment(
          a.lastupdate?.props?.children?.props?.children.map((x) => x)[1],
          "DD.MM.YYYY HH.mm"
        ).toDate();

        let t2 = moment(
          b.lastupdate?.props?.children?.props?.children.map((x) => x)[1],
          "DD.MM.YYYY HH.mm"
        ).toDate();
        return moment(t1).unix() - moment(t2).unix();
      },
    },

    {
      title: "",
      dataIndex: "action",
    },
  ];

  let showModal = () => {
    setIsModalVisible(true);
  };

  let handleCancel = () => {
    setIsModalVisible(false);
  };

  let cancelHandleChange = (id) => {
    setCancellingBidId(id);
  };

  let cancelChange = () => {
    setIsModalVisible(false);

    let params = {
      BidId: `BVE-DMP-BIDMGMT-WORK ${cancelingBidId}`,
      caseTypeID: "BVE-DMP-BidMgmt-Work-BidMgmt",
      processID: "pyStartCase",
      content: {
        CaseData: {
          KeyIndicator: "Cancel",
        },
      },
    };
    let requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(hostURL + `/api/bidmanager/Bids/Updatebid`, requestOptionsvalue)
      .then((response) => {
        let dataJson = response.json();
        setMyBidStatus(response.status);
        return dataJson;
      })
      .then((data) => data);

    const timer = setTimeout(() => {
      window.location.reload(true);
    }, 5000);
  };

  //Timeout page refresh End

  const getAllBitInfo = useCallback(() => {
    let requestGetOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
    };

    fetch(
      hostURL + `/api/bidmanager/Bids/All/${JSON.parse(getUserid)}`,
      requestGetOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setBiData(data);

        if (_.isEmpty(finalVehicleList)) {
          let vehicleListHolder = [];
          const requestGetOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(getToken),
            },
          };
          let vehList = data?.pxResults
            .map((rowApi, key) => {
              if (
                rowApi.bidInfoPage.bidStatus === "Bid Placed" ||
                rowApi.bidInfoPage.bidStatus === "AWAITING RESULTS" ||
                rowApi.bidInfoPage.bidStatus === "To be Confirmed" ||
                rowApi.bidInfoPage.bidStatus === "Won" ||
                rowApi.bidInfoPage.bidStatus === "Lost" ||
                rowApi.bidInfoPage.bidStatus === "Fail"
              ) {
                let PromiseVehiclelist = fetch(
                  hostURL +
                    `/api/auction/VehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  requestGetOptions
                )
                  .then((response) => response.json())
                  .then((data) => {
                    if (!_.isEmpty(data)) {
                      let vehicle = {
                        ...data,
                        ...{
                          bidInfoPage: rowApi.bidInfoPage,
                          priceSettings: { minBid: rowApi.bidInfoPage.minBid },
                          itemInfo: {
                            id: rowApi.bidInfoPage.vehicleId,
                            licenceplate: rowApi.pxPages.vehicle.licenceplate,
                            modelType: data.modelType,
                            brand: data.brand,
                            transmission: data.transmission,
                          },
                          auctionParameters: {
                            closedForBidding:
                              rowApi.bidInfoPage.auctionEndDtTime,
                          },
                          vehiclePxData: rowApi.pxPages.vehicle,

                          uniqueId: rowApi.bidInfoPage.lastUpdated,
                          bidID: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                        },
                      };
                      vehicleListHolder.push(vehicle);

                      return vehicleListHolder;
                    }
                  })
                  .catch((err) => {
                    let vehicle = {
                      ...{
                        bidInfoPage: rowApi.bidInfoPage,
                        priceSettings: { minBid: rowApi.bidInfoPage.minBid },
                        itemInfo: {
                          id: rowApi.bidInfoPage.vehicleId,
                          licenceplate: rowApi.pxPages.vehicle.licenceplate,
                          modelType: data.modelType,
                          brand: data.brand,
                          transmission: data.transmission,
                        },
                        auctionParameters: {
                          closedForBidding: rowApi.bidInfoPage.auctionEndDtTime,
                        },
                        vehiclePxData: rowApi.pxPages.vehicle,

                        uniqueId: rowApi.bidInfoPage.lastUpdated,
                        bidID: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                      },
                    };
                    vehicleListHolder.push(vehicle);

                    return vehicleListHolder;
                  });

                return PromiseVehiclelist;
              }
            })
            .filter(Boolean);

          Promise.all(vehList).then((arrayOfValues) => {
            let sortedVehicleList = arrayOfValues[
              arrayOfValues.length - 1
            ].sort((a, b) => {
              return (
                new Date(b.bidInfoPage.lastUpdated) -
                new Date(a.bidInfoPage.lastUpdated)
              );
            });

            setFinalVehicleList(sortedVehicleList);
          });
        } else {
          prepareTableRows(data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken, getUserid, hostURL]);

  useEffect(() => {
    if (finalVehicleList.length > 0 && !_.isEmpty(bitData)) {
      prepareTableRows(bitData);
    }
  }, [finalVehicleList, bitData]);

  const prepareTableRows = useCallback(
    (data) => {
      let dataviewall = data || bitData;
      let columnsDetails = [];

      if (dataviewall.pxResults) {
        const updatedRowData = dataviewall.pxResults.filter((data) => {
          return (
            data.pyID === bidId && data.bidInfoPage.bidStatus === "Bid Placed"
          );
        });
        setUpdatedBitRowData(updatedRowData);
      }

      dataviewall?.pxResults.map((rowApi, key) => {
        let nowDate = moment(new Date());
        let lastupdateDate = moment(new Date(rowApi.bidInfoPage.lastUpdated));
        let enddate = moment(new Date(rowApi.bidInfoPage.auctionEndDtTime));
        let startDate = moment(new Date(rowApi.bidInfoPage.auctionStartDtTime));
        let totalhours = 24;
        let enddatediffrence = moment.duration(enddate.diff(nowDate));
        let hoursenddatediffrence = enddatediffrence.days();
        let remainingenddatediffrence = hoursenddatediffrence * totalhours;
        let enddatedifferencehours = enddatediffrence.asHours();
        enddatedifferencehours = enddatedifferencehours.toFixed(0);
        let enddatedifferenceminutes = enddatediffrence.minutes();
        let enddatedifferenceseconds = enddatediffrence.seconds();
        moment.locale("nl", localization);
        let enddateFormat = moment(rowApi.bidInfoPage.auctionEndDtTime).format(
          "ll"
        );
        let endhoursFormat = moment(
          rowApi.bidInfoPage.auctionEndDtTime
        ).hours();
        let endminutesFormat = moment(
          rowApi.bidInfoPage.auctionEndDtTime
        ).minutes();

        let startdateFormat = moment(
          rowApi.bidInfoPage.auctionStartDtTime
        ).format("ll");
        let starthoursFormat = moment(
          rowApi.bidInfoPage.auctionStartDtTime
        ).hours();
        let startminutesFormat = moment(
          rowApi.bidInfoPage.auctionStartDtTime
        ).minutes();
        setRowstatus(rowApi.bidInfoPage.bidStatus);

        let photoTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi?.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <Spin />
                </div>
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          } else {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <div className="mybid-image-holder">
                  <img
                    src={testURL + `${rowApi.bidInfoPage.url}`}
                    width="65px"
                    height="55px"
                    alt="foto beschikbaar"
                  />
                </div>
              </Link>
            );
          }
        };

        let plateTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.licenceplate}
              </Link>
            );
          }
        };

        let brandTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.brand}
              </Link>
            );
          }
        };

        let modelTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                {rowApi.pxPages.vehicle.model}
              </Link>
            );
          }
        };

        let bidstatusTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
                className="running-status-option"
              >
                Bieding geplaatst
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
                className="waiting-status-option"
              >
                In behandeling
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
                className="won-status-option"
              >
                Gewonnen
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
                className="lost-status-option"
              >
                Verloren
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
                className="tobeconfirmed-status-option"
              >
                Verstuurd
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
                className="fail-status-option"
              >
                Niet gelukt
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
                className="running-status-option"
              >
                {rowApi.bidInfoPage.bidStatus}
              </Link>
            );
          }
        };

        let startdateTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(startDate, "yyyy-mm-dd")}
                  </span>
                  <span>{startdateFormat}-</span>
                  <span>
                    {" "}
                    {starthoursFormat}u{startminutesFormat}
                  </span>
                </span>
              </Link>
            );
          }
        };

        let enddateTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>
                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="date-format">
                  <span className="customTimeFormat">
                    {dateFormat(enddate, "yyyy-mm-dd")}
                  </span>
                  <span>{enddateFormat}-</span>

                  <span>
                    {" "}
                    {endhoursFormat}u{endminutesFormat}
                  </span>
                </span>
              </Link>
            );
          }
        };

        let bidamountTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={false}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                  handleBitChange={updateBitChange}
                />
              </div>
            );
          } else if (e === "AWAITING RESULTS") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={true}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          } else if (e === "Won") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={true}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          } else if (e === "Lost") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={true}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          } else if (e === "To be Confirmed") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={true}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          } else if (e === "Fail") {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={true}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          } else {
            return (
              <div>
                <UpdateButton
                  bidInfoPage={rowApi?.bidInfoPage}
                  disableOption={false}
                  bidId={`BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`}
                  accesstoken={JSON.parse(getToken)}
                  className="table-update-button"
                />
              </div>
            );
          }
        };

        let timerTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <MybidsTimer
                hours={enddatedifferencehours}
                minutes={enddatedifferenceminutes}
                seconds={enddatedifferenceseconds}
                totalhoursleft={remainingenddatediffrence}
                enddayofbidding={enddate}
              />
            );
          } else {
            return (
              <span
                style={{ width: "145px", display: "inline-block" }}
                className="adjusting-timer-width"
              ></span>
            );
          }
        };

        let lastupdateTable = (e) => {
          if (e === "Bid Placed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else if (e === "AWAITING RESULTS") {
            // waiting

            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else if (e === "Won") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else if (e === "Lost") {
            // lost
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else if (e === "To be Confirmed") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else if (e === "Fail") {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidStatus: rowApi.bidInfoPage.bidStatus,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          } else {
            return (
              <Link
                to={{
                  pathname: `carpassportslider/vehicleById/${rowApi.bidInfoPage.vehicleId}`,
                  state: {
                    currentIndex: _.findIndex(finalVehicleList, (vDetail) => {
                      return _.isEqual(
                        _.get(vDetail, "bidID"),
                        `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`
                      );
                    }),
                    uniqueId: rowApi.bidInfoPage.lastUpdated,
                    isFromMyBid: true,
                    vehiclesDetail: finalVehicleList,
                    licencePlate: rowApi.pxPages.vehicle.licenceplate,
                    vehicleId: rowApi.bidInfoPage.vehicleId,
                    BidAmount: Number(rowApi?.bidInfoPage?.bidAmount),
                    bidId: `BVE-DMP-BIDMGMT-WORK ${rowApi.pyID}`,
                    enddayofbidding: rowApi.bidInfoPage.auctionEndDtTime,
                    bidInfoPage: rowApi.bidInfoPage,
                    openForBidding: rowApi.bidInfoPage.auctionStartDtTime,
                  },
                }}
              >
                <span className="customTimeFormat">
                  {dateFormat(lastupdateDate, "yyyy-mm-dd")}
                </span>
                {dateFormat(lastupdateDate, "dd-mm-yy") +
                  " " +
                  dateFormat(rowApi.bidInfoPage.lastUpdated, "HH:MM")}
              </Link>
            );
          }
        };

        let actionTable = (e) => {
          if (e === "Bid Placed") {            
            return null;
          } else if (e === "AWAITING RESULTS") {
            return null;
          } else if (e === "Won") {
            return null;
          } else if (e === "Lost") {
            return null;
          } else if (e === "Fail") {
            return null;
          } else {
            return null;
          }
        };

        let coloumData = {
          border: (
            <div
              className={
                rowApi.bidInfoPage.bidStatus === "Bid Placed"
                  ? "running-border-style"
                  : rowApi.bidInfoPage.bidStatus === "AWAITING RESULTS"
                  ? "awaiting-border-style"
                  : rowApi.bidInfoPage.bidStatus === "Won"
                  ? "won-border-style"
                  : rowApi.bidInfoPage.bidStatus === "Lost"
                  ? "lost-border-style"
                  : "fail-border-style"
              }
            ></div>
          ),
          photo: <div>{photoTable(rowApi.bidInfoPage.bidStatus)}</div>,
          plate: (
            <div style={{ marginLeft: "12px" }}>
              {plateTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          brand: (
            <div style={{ marginLeft: "21px", fontSize: " 9.55px" }}>
              {brandTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          model: (
            <div style={{ marginLeft: "24px", fontSize: " 9.5px" }}>
              {modelTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          status: (
            <div style={{ width: "100px", marginLeft: "8px" }}>
              {bidstatusTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          start_date_time: (
            <div style={{ marginLeft: "8px" }}>
              {startdateTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          end_date_time: (
            <div style={{ marginLeft: "8px" }}>
              {enddateTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          bidamount: (
            <div style={{ marginLeft: "8px" }}>
              {bidamountTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          timer: (
            <div style={{ marginLeft: "8px" }}>
              {timerTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          lastupdate: (
            <div style={{ marginLeft: "14px", fontSize: " 10.55px" }}>
              {lastupdateTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
          action: (
            <div onClick={showModal}>
              {actionTable(rowApi.bidInfoPage.bidStatus)}
            </div>
          ),
        };

        if (
          rowApi.bidInfoPage.bidStatus === "Bid Placed" ||
          rowApi.bidInfoPage.bidStatus === "AWAITING RESULTS" ||
          rowApi.bidInfoPage.bidStatus === "To be Confirmed" ||
          rowApi.bidInfoPage.bidStatus === "Won" ||
          rowApi.bidInfoPage.bidStatus === "Lost" ||
          rowApi.bidInfoPage.bidStatus === "Fail"
        ) {
          columnsDetails.push(coloumData);
        }
      });

      setRowFilterdata(columnsDetails);
      setTableLoaderFlag(false);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [bitData, finalVehicleList]
  );

  const showOnNoData = () => {
    return (
      <div className="no-running-bids-parent-container">
        <div className="no-running-bids-container">
          <NorunningbidsIcon />
        </div>
        <span className="no-running-bids-container-span">
          U heeft geen lopende biedingen!
        </span>
      </div>
    );
  };

  function onChange(pagination, filters, sorter, extra, currentDataSource) {
    let sortedBidIdList = extra.currentDataSource.map((currentRow, key) => {
      return currentRow.lastupdate.props.children.props.to.state.bidId;
    });
    let sortValue = {
      field: sorter.field,
      order: sorter.order,
      sortedListOrder: sortedBidIdList,
    };

    setCurrentSortValue(sortValue);
  }

  return (
    <div data-testid="mybids-tables-container">
      <Table
        scroll={{ x: 100 }}
        className="mybid-table"
        columns={columns}
        loading={{ indicator: <div className="mybidLoader">
        <LoaderIndex type="bubbles" color="gray" height="38px" width="51px"/>
        </div>,spinning:myBidStatus !== 401 &&tableLoaderFlag}}
        dataSource={myBidStatus === 401 ? showOnNoData : rowFilterdata}
        onChange={onChange}
        pagination={false}
        rowClassName={
          rowstatus === "mybid-tbody-row-running"
            ? "running-border-row"
            : rowstatus === "AWAITING RESULTS"
            ? "awaiting-border-row"
            : null
        }
      />
      <div className="modal-container">
        <Modal
          title="Bod intrekken"
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <Button className="ant-modal-button1" onClick={handleCancel}>
              Annuleren
            </Button>,
            <Button className="ant-modal-button2" onClick={cancelChange}>
              Bevestigen
            </Button>,
          ]}
        >
          <p className="modal-body-message">
            Weet je zeker dat je dit bod wilt intrekken?
          </p>
        </Modal>
      </div>
    </div>
  );
}

export default DataTables;
