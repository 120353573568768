import React, { useEffect, useState, Fragment, useCallback, lazy, Suspense} from "react";
import Slider from "react-slick";
import moment from "moment";
import _ from "lodash";
import SingleLotCard from "../components/Cards/singleLotCard";
import "../components/Cards/SortOfCards/sortOfCards.css";
import "./avaibleCars.css";
import { useLocation } from "react-router-dom";
import LoaderIndex from "../components/LoaderComponent/LoaderIndex";

const SidebarOverlay = lazy(() => import("../components/SidebarOverlay/sidebarOverlay"));
const SortOfCards = lazy(() => import("../components/Cards/SortOfCards/sortOfCards"));
const NorunningupcomingauctionIcon = lazy(() => import("../components/Icons/AuctionDashboard/norunningupcomingauctionIcon"));


const hostURL = process.env.REACT_APP_SERVER_URL;
const testURL = process.env.REACT_APP_TESTAPI_URL;
function AvailableCars(props) {
  const location = useLocation();
  const stateCatogId = _.get(location, "state.catalogIDs");
  const stateVehicleList = _.get(location, "state.vehiclesList");

  const [getToken, setGetToken] = useState(localStorage.getItem("Token"));
  const [getUserid, setGetUserid] = useState(localStorage.getItem("userid"));

  const auctionDashboardId = props.location && props.location.dataAuction;
  // const [timerHours, setTimerHours] = useState();
  // const [auctionDetails, setAuctionDetails] = useState([]);
  //Added
  const [eligibleAuctionDetails, setEligibleAuctionDetails] = useState([]);
  const [vehiclesAllDetails, setVehiclesAllDetails] = useState([]);
  const [vehiclesDetails, setVehiclesDetails] = useState([]);
  const [selectedAuctionIds, setSelectedAuctionIds] = useState([]);

  const [myAuctionStatus, setMyAuctionStatus] = useState([]);
  const [myAuctionresponse, setMyAuctionresponse] = useState([]);
  const [vechicleByAuctionCache, setVechicleByAuctionCache] = useState([]);
  const [isFilterApply, setFilterApply] = useState(false);
  const [
    defaultFilterAuctionSelectedCatelogId,
    setDefaultFilterAuctionSelectedCatelogId,
  ] = useState([]);

  const inputnArray = (inputArr) => {
    let FilterObj = inputArr;

    FilterObj.filter((e) => e !== null);

    const params = {
      filters: FilterObj,
      operator: "And",
    };
    manualvehiclesAPI(params, FilterObj);
  };

  const manualvehiclesAPI = (params, FilterObj) => {
    const requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(testURL + `/api/v1/auctions/lots/vehicle/filter`, requestOptionsvalue)
      .then((response) => response.json())
      .then((data) => {
        let setTrans = [];
        setTrans.push(data);

        let automatic_filter1 = [];
        let automatic_filter2 = [];
        let automatic_filterfuelTrans = [];
        let automatic_filterFuel = [];
        let automatic_filterTrans = [];

        for (let i = 0; i < vehiclesDetails.length; i++) {
          automatic_filter1?.push({
            ...vehiclesDetails[i],
            ...setTrans.find(
              (itemInfo) => itemInfo.catalogID == vehiclesDetails[i].id
            ),
          });
        }

        automatic_filter1.map((alldata) => {
          FilterObj.map((data) => {
            if (alldata.itemInfo.transmission === data.value) {
              automatic_filterTrans.push(alldata);
            }
          });
          FilterObj.map((data) => {
            if (alldata.itemInfo.fuelType === data.value) {
              automatic_filterFuel.push(alldata);
            }
          });

          FilterObj.map((data) => {
            if (
              alldata.itemInfo.fuelType === data.value &&
              alldata.itemInfo.transmission === data.value
            ) {
              automatic_filterfuelTrans.push(alldata);
            }
          });
        });

        let concatFilterArray = [].concat(
          automatic_filterTrans,
          automatic_filterFuel
        );

        var union = [...new Set(concatFilterArray)];

        setVehiclesDetails(union.length > 0 ? union : vehiclesDetails);
      });
  };

  useEffect(() => {
    if (_.get(props, "history.location.state.catalogIDs")) {
      setSelectedAuctionIds(_.get(props, "history.location.state.catalogIDs"));
      setVehiclesDetails(_.get(props, "history.location.state.vehiclesList"));
    }
  }, [props]);
  // -----sortOfcard Clickhandler  --------//

  const auctionCardHandler = (id) => {
    // Code for multiple selection
    setFilterApply(false);
    let updatedIds = [];
    if (selectedAuctionIds.length > 1 && selectedAuctionIds.includes(id)) {
      updatedIds = selectedAuctionIds.filter((auctionId) => {
        return auctionId !== id;
      });
      setSelectedAuctionIds(updatedIds);
    } else {
      if (!selectedAuctionIds.includes(id)) {
        updatedIds = [...selectedAuctionIds, ...[id]];
        setSelectedAuctionIds(updatedIds);
      }
    }
    setDefaultFilterAuctionSelectedCatelogId(updatedIds);
  };

  useEffect(() => {
    // only update after setting stateValue selectedAuctionIds
    if (!_.isEmpty(selectedAuctionIds) && !isFilterApply) {
      setVehiclesDetails(
        vehiclesAllDetails.filter((vechicles) => {
          return selectedAuctionIds.includes(vechicles.catalogID);
        })
      );
    }
  }, [selectedAuctionIds]);

  // -----sortOfcard Clickhandler section ends ----/
  useEffect(() => {
    onRenderFetch();
  }, []);

  const onRenderFetch = () => {
    const params = {
      filters: [
        {
          field: "Status",
          value: "OpenForBidding",
          operator: "Equals",
        },
        {
          field: "Status",
          value: "Published",
          operator: "Equals",
        },
        {
          field: "Status",
          value: "InConsideration",
          operator: "Equals",
        },
      ],
      operator: "Or",
    };

    const requestGetOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(hostURL + "/api/auction/GetAuctionByStatus", requestGetOptions)
      // fetch("https://testapi.biddo.nl/api/v1/auctions/catalogs/all/status/Running")
      .then((response) => {
        let dataJson = response.json();
        setMyAuctionStatus(response.status);
        setMyAuctionresponse(response);
        // console.log('dataJson',dataJson);
        return dataJson;
      })
      .then((data) => {
        // setAuctionDetails(data);
        // POST request using fetch inside useEffect React hook
        const auctionfiltersList = [];
        data.map((auctionAPI, key) => {
          const filter = {
            field: "CatalogID",
            value: auctionAPI.id,
            status: auctionAPI.status,
            operator: "Equals",
          };
          auctionfiltersList.push(filter);
        });
        const params = {
          filters: auctionfiltersList,
          operator: "Or",
        };
        vehiclesAPI(params, data, true);
      });
  };

  const vehiclesAPI = (params, auctionaAllData, isCache) => {
    const requestOptionsvalue = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: JSON.parse(getToken),
      },
      body: JSON.stringify(params),
    };

    fetch(
      hostURL +
        `/api/auction/FilterVehicleLotsByMultipleCatalogIds?userId=${JSON.parse(
          getUserid
        )}`,
      requestOptionsvalue
    )
      .then((response) => response.json())
      .then((data) => {
        let merged = [];
        // console.log(data);
        for (let i = 0; i < data.length; i++) {
          merged.push({
            ...data[i],
            ...auctionaAllData.find(
              (itmInner) => itmInner.id === data[i].catalogID
            ),
          });
        }

        setVehiclesDetails(merged);
        setVehiclesAllDetails(merged);
        if (auctionDashboardId) {
          setVehiclesDetails(
            merged.filter((vechicles) => {
              return vechicles.catalogID === auctionDashboardId;
            })
          );
        } else if (stateCatogId) {
          if (stateCatogId) {
            setSelectedAuctionIds(stateCatogId);
            setVehiclesDetails(stateVehicleList);
          }
        }

        let auctionMerge = [];
        for (let i = 0; i < auctionaAllData.length; i++) {
          auctionMerge.push({
            ...auctionaAllData[i],
            ...data.find(
              (itmInners) => itmInners.catalogID === auctionaAllData[i].id
            ),
          });
        }

        if (auctionMerge) {
          let filteredAuctionDetails = auctionMerge.filter((auctionAPI) =>
            filterWithBandAndTime(auctionAPI, data, isCache)
          );

          const auctionDetailsItems = auctionMerge.concat(
            filteredAuctionDetails
          );

          let uniqueauctionDetailsItems = [...new Set(auctionDetailsItems)];

          uniqueauctionDetailsItems.sort((a, b) => {
            let aStatus = a.status,
              bStatus = b.status,
              aEndTime = new Date(a.auctionParameters.closedForBidding),
              bEndTime = new Date(b.auctionParameters.closedForBidding);
            return aStatus === bStatus
              ? aEndTime - bEndTime
              : aStatus === "OpenForBidding"
              ? -1
              : 1;
          });
          let noUpcomingAuction = uniqueauctionDetailsItems.filter(
            (auction) => auction.status !== "Published"
          );
          let upcomingAuction = uniqueauctionDetailsItems
            .filter((auction) => auction.status === "Published")
            .sort((a, b) => {
              let aEndTime = new Date(a.openForBidding),
                bEndTime = new Date(b.openForBidding);
              return aEndTime - bEndTime;
            });
            uniqueauctionDetailsItems = [...noUpcomingAuction, ...upcomingAuction];

          setEligibleAuctionDetails(uniqueauctionDetailsItems);
        }
      });
  };

  // only one needed
  const filterBrandByCatalog = (
    catalogID,
    lotStatus,
    data = vechicleByAuctionCache
  ) => {
    const brandLogo = [];
    data.forEach((vehicle) => {
      if (vehicle.catalogID === catalogID && vehicle.lotStatus === lotStatus) {
        brandLogo.push(vehicle.itemInfo.brandLogo);
      }
    });
    return brandLogo;
  };

  const applyFilterValues = useCallback(
    (auctionFilterValues, vehicleListBasedonAuction) => {
      setFilterApply(true);
      setSelectedAuctionIds(auctionFilterValues);
      setVehiclesDetails(vehicleListBasedonAuction);
    },
    []
  );
  // ----- remove item with remaining hours zero and no brands--start//
  const filterWithBandAndTime = (auctionAPI, data, isCache) => {
    if (isCache) {
      setVechicleByAuctionCache(data);
    }

    // console.log('filterWithBandAndTime',vechicleByAuctionCache);

    let now = moment(new Date());
    let end = moment(auctionAPI?.auctionParameters?.closedForBidding);
    let diff = moment.duration(end.diff(now));

    let remainingtimehours = diff.asHours();
    const hasActiveBrand = filterBrandByCatalog(
      auctionAPI.catalogID,
      auctionAPI.lotStatus,
      data
    ).length;
    return remainingtimehours > 0 && hasActiveBrand;
  };
  // ----- remove item with remaining hours zero and no brands--end//

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    slidesToScroll: 1,
    variableWidth: true,
  };

  let eligibleIndex = 0;

  return (
    <section
      className="available-onboarding"
      data-testid="avail-cars-component"
    >

      <div className="wrapper">
        <div className="availablecar-sortCard-sideoverlay">
        <Suspense fallback={""}>
          <SidebarOverlay
            vehicleList={vehiclesAllDetails}
            auctionList={eligibleAuctionDetails}
            selectedAuctionIds={selectedAuctionIds}
            // vehiclesDetails={vehiclesDetails}
            defaultFilterAuctionSelectedCatelogId={
              defaultFilterAuctionSelectedCatelogId
            }
            applyFilterValues={applyFilterValues}
          />
        </Suspense>
        </div>

        {myAuctionStatus === 401 || myAuctionStatus === 500 ? (
          <div className="no-running-upcoming-auctions-parent-container">
            <div className="no-running-upcoming-auctions-container">
              <Suspense fallback={""}>
                <NorunningupcomingauctionIcon />
              </Suspense>
            </div>
            <span className="no-running-upcoming-auctions-container-span">
              Geen lopende of aanstaande veiling beschikbaar
            </span>
          </div>
        ) : (
          <Fragment>
          {!_.isEmpty(eligibleAuctionDetails) ? 
          <div>
            <div className="availablecar-sortCard">
              <Slider {...settings}>
                {eligibleAuctionDetails &&
                  eligibleAuctionDetails.map((auctionAPI, key) => {
                    // console.log('eligibleAuctionDetails',auctionAPI);

                    let eligibleAuctionDetailsIndex = eligibleIndex + 1;
                    let now = moment(new Date());
                    let end = moment(
                      auctionAPI?.auctionParameters?.closedForBidding
                    );
                    let endday = moment(
                      new Date(auctionAPI?.auctionParameters?.closedForBidding)
                    );
                    let diff = moment.duration(end.diff(now));

                    let remainingtimehours = diff.asHours();
                    let remainingtimeminutes = diff.minutes();
                    let remainingtimesecondss = diff.seconds();

                    if (_.isEmpty(selectedAuctionIds)) {
                      // ifAuctionId - coming from dashboard then that will be default
                      auctionCardHandler(
                        auctionDashboardId
                          ? auctionDashboardId
                          : eligibleAuctionDetails[0].id
                      );
                    }
                    // console.log(auctionAPI);
                    return (
                      <>
                        <Fragment>
                          {end > now || end < now - 1 ? (
                          <Suspense fallback={""}>

                            <SortOfCards
                              auctionStatus={auctionAPI?.status}
                              profileName={auctionAPI?.profile?.name}
                              onChange={auctionCardHandler}
                              id={auctionAPI.id}
                              firstItemId={key == 0 && auctionAPI.id}
                              catalogID={auctionAPI.auctionParameters.catalogID}
                              openForBidding={auctionAPI.openForBidding}
                              enddayofbidding={
                                auctionAPI?.auctionParameters?.closedForBidding
                              }
                              remainingtimehour={remainingtimehours}
                              remainingtimeminute={remainingtimeminutes}
                              remainingtimesecond={remainingtimesecondss}
                              minBidExpirationDays={
                                auctionAPI.auctionParameters
                                  .minBidExpirationDays
                              }
                              brandLogos={filterBrandByCatalog(
                                auctionAPI.catalogID,
                                auctionAPI.lotStatus
                              )}
                              firstSelection={selectedAuctionIds[0]}
                              status={
                                !_.isEmpty(selectedAuctionIds)
                                  ? selectedAuctionIds.includes(auctionAPI.id)
                                    ? true
                                    : false
                                  : false
                              }
                              description={auctionAPI.description}
                              end={end}
                              start={now}
                              key={key}
                              vehiclesDetail={vehiclesDetails}
                            />
                          </Suspense>
                          ) : null}
                        </Fragment>
                      </>
                    );
                  })}
              </Slider>
            </div>
            <div className="availablecar-singlelots-card">
              <div className="availablecar-singlelots-card-container">
                {vehiclesDetails &&
                  vehiclesDetails.map((allvehicles, key) => {
                    let countDownDate = new Date(
                      allvehicles?.auctionParameters?.closedForBidding
                    ).getTime();

                    let nows = moment(new Date().getTime());
                    let distance = countDownDate - nows;
                    // console.log("allvehicles", allvehicles);
                    return (
                      <>
                        {(distance > 0 &&
                          allvehicles.status !== "Draft" &&
                          allvehicles.status === "OpenForBidding" &&
                          allvehicles.lotStatus === "OpenForBidding") ||
                        (allvehicles.status === "Published" &&
                          (allvehicles.lotStatus === "Published" ||
                            allvehicles.status === "OpenForBidding")) ? (
                          <div className="availablecar-singlelots-cards-sub">
                            <Suspense fallback={""}>
                              <SingleLotCard
                                key={allvehicles.id}
                                id={allvehicles.catalogID}
                                licencePlate={
                                  allvehicles?.itemInfo?.licenceplateDisplay ===
                                  null
                                    ? allvehicles?.itemInfo?.licenceplate
                                    : allvehicles?.itemInfo?.licenceplateDisplay
                                }
                                model={allvehicles.itemInfo.model}
                                modelType={allvehicles.itemInfo.modelType}
                                brand={allvehicles.itemInfo.brand}
                                coachType={allvehicles.itemInfo?.coachType}
                                fuelType={allvehicles.itemInfo.fuelType}
                                jaar={allvehicles.itemInfo.modelYear}
                                milage={allvehicles.itemInfo.mileage}
                                version={allvehicles.itemInfo.version}
                                transmission={allvehicles.itemInfo.transmission}
                                medias={allvehicles.itemInfo.medias}
                                vehiclesID={allvehicles.itemInfo.id}
                                damagesCount={allvehicles.itemInfo.damagesCount}
                                openForBidding={allvehicles.openForBidding}
                                minBidExpirationDays={
                                  allvehicles?.auctionParameters
                                    ?.minBidExpirationDays
                                }
                                minBid={allvehicles?.priceSettings?.minBid}
                                bidInfoPage={allvehicles.bidInfoPage}
                                status={allvehicles.status}
                                accesstoken={JSON.parse(getToken)}
                                userid={JSON.parse(getUserid)}
                                lotID={allvehicles.lotId}
                                enddayofbidding={
                                  allvehicles?.auctionParameters?.closedForBidding
                                }
                                vehiclesDetail={vehiclesDetails}
                              />
                            </Suspense>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>  :
          <div className="availableCarLoader">
            <LoaderIndex type="bubbles" height={"38px"} width={"51px"} color="gray"/>
          </div>}
          </Fragment>
        )}
      
      </div>
     
    </section>
  );
}
export default AvailableCars;
