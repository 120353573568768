// import { withConfigConsumer } from "antd/lib/config-provider/context";
import React from "react";
import "./InformatieOverBandenContainer.css";

function InformatieOverBandenContainer(props) {

  let threadLF = props?.type[0]?.threadLF;

  let threadLR = props?.type[0]?.threadLR;
  let threadRF = props?.type[0]?.threadRF;
  let threadRR = props?.type[0]?.threadRR;
  let diameter = props?.type[0]?.diameter;
  let noData = "-";

  let type =
    props?.type[0]?.type === "Summer"
      ? "Zomerbanden"
      : props?.type[0]?.type === "Winter"
      ? "Winterbanden"
      : "Onbekend";
  return (
    <React.Fragment>
      <div className="informatieOverBanden-container-title">
        <h4>informatie over banden</h4>
      </div>

      <div className="informatieOverBanden-container-body-block">
        <div className="informatieOverBanden-container-body-columns">
          <div className="informatieOverBanden-container-body-columns-info-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Type banden
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {/* Hele seizoen */}
              {type ? type : "-"}
            </span>
          </div>

          <div className="informatieOverBanden-container-body-columns-info-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Profiel rechtsvoor (mm)
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {threadRF ? threadRF : noData}
            </span>
          </div>
          <div className="informatieOverBanden-container-body-columns-info-last-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Profiel linksvoor (mm)
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {threadLF ? threadLF : "-"}
            </span>
          </div>
        </div>
        <div className="informatieOverBanden-container-body-columns">
          <div className="informatieOverBanden-container-body-columns-info-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Diameter velg (inch)
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {/* 123 */}
              {diameter ? diameter : "-"}
            </span>
          </div>
          <div className="financien-container-body-columns-info-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Profiel linksachter (mm)
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {/* 123 */}

              {threadLR ? threadLR : noData}
            </span>
          </div>
          <div className="financien-container-body-columns-info-block">
            <span className="informatieOverBanden-container-body-columns-info-block-title">
              Profiel rechtsachter (mm)
            </span>
            <span className="informatieOverBanden-container-body-columns-info-block-value">
              {/* 123 */}

              {threadRR ? threadRR : noData}
            </span>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default InformatieOverBandenContainer;
