import React from "react";
import MlHeaderlogo from "../components/Marketing-landingpage/mlHeaderlogo";
import "./marketing-landingpage-style.css";
import MlHero from "../components/Marketing-landingpage/mlHero";
import MlDescriptionpage from "../components/Marketing-landingpage/mlDescriptionpage";
import MlSignupForm from "../components/Marketing-landingpage/mlSignupForm";
import MlStepprocess from "../components/Marketing-landingpage/mlStepprocess";
import MlFAQComponent from "../components/Marketing-landingpage/mlFaq";
import Footercomponent from "../components/Marketing-landingpage/footercomponent";
function MarketingLandingPage() {
  return (
    <div className="marketing-landingpage-container">
      <header>
        <div className="header-section">
          <MlHeaderlogo />
        </div>
      </header>
      <main>
        <section>
          <div className="hero-section">
            <MlHero />
          </div>
        </section>
        <section>
          <div className="middle-section">
            <div className="mldescription">
              <MlDescriptionpage />
            </div>
            <div className="signupform">
              <MlSignupForm />
            </div>
          </div>
        </section>
        <section>
          <div className="mlpstep-section">
            <MlStepprocess />
          </div>
        </section>
        <section>
          <div className="FAQ-section">
            <MlFAQComponent />
          </div>
        </section>
      </main>

      <footer className="footer-section">
        <Footercomponent />
      </footer>
    </div>
  );
}

export default MarketingLandingPage;
