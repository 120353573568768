import React from 'react'

function SlidePrevIcon() {
    return (
        <div>
            
<svg width="7.41" height="12" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.88021 1.91L6.47021 0.5L0.470215 6.5L6.47021 12.5L7.88021 11.09L3.30021 6.5L7.88021 1.91Z" fill="#2A69B2"/>
</svg>

        </div>
    )
}

export default SlidePrevIcon
